<template>
  <c-dialog
    class="video-dialog"
    :confirm-option="false"
    :min-width="isMobile ? 'auto' : dialogWidth"
    :model-value="modelValue"
    width="auto"
    @cancel="emit('update:modelValue', false)"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #title>{{ props.video.name }}</template>
    <template #content>
      <circle-loader
        v-if="!isVideoLoaded"
        :size="60"
        title="Loading video data..."
      />
      <div v-show="isVideoLoaded">
        <video-js-player
          :key="componentKey"
          ref="videoPlayer"
          :hide-background="video.lipsyncId && !video.portraitId"
          :options="{
            autoplay: false,
            controls: true,
            preload: `auto`,
            height: videoJsHeight,
            sources: [
              {
                src: video.url,
                type: 'video/mp4',
              },
            ],
          }"
          :variant="VideoPlayerVariants.GALLERY"
          @loaded="videoLoaded"
        />
      </div>

      <div class="actions d-flex mt-6 w-100">
        <c-button
          v-if="share"
          class="actions__btn"
          mode="secondary"
          prepend-icon="ph:share-network-fill"
          @click="shareVideo"
        >Share</c-button
        >
        <c-button
          class="actions__btn"
          :mode="isMobile ? 'secondary' : 'primary'"
          :prepend-icon="isMobile ? 'ph:download-simple' : ''"
          @click="downloadVideo"
        >Download</c-button
        >
      </div>
    </template>
  </c-dialog>
</template>

<script setup lang="ts">
  import CircleLoader from "@/core/components/Container/CircleLoader.vue";
  import { Routes } from "@/core/routes/core.guard";
  import { isMobile } from "@/core/utils/mobile";
  import { nativeShare } from "@/core/utils/share";
  import { useDialogsStore } from "@/core/store/useDialogsStore";
  import { useRouter } from "vue-router";
  import VideoJsPlayer from "../Players/VideoJsPlayer.vue";
  import { VideoPlayerVariants } from "@/core/types/video.types";
  import { nextTick, onBeforeUnmount, onMounted, type PropType, ref, toRefs, watch } from "vue";
  import {
    PLAYGROUND_FEATURES,
    type PlaygroundFeature,
  } from "@/core/data/playgroundFeatures";

  // @ts-ignore
  import { CButton } from "@charactr/wooper-ui/atoms";
  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";

  const props = defineProps({
    modelValue: {
      type: Boolean,
      required: true,
    },
    video: {
      type: Object as PropType<any>,
      default: null,
    },
    mode: {
      type: null as unknown as PropType<PlaygroundFeature["name"]>,
      default: PLAYGROUND_FEATURES.VTV.name,
    },
    share: {
      type: Boolean,
      default: true,
    },
  });

  const emit = defineEmits(["update:modelValue", "downloadRecording"]);

  //STORE
  const dialogsStore= useDialogsStore();
  const { allowRecordingsHistoryScreenResize } = toRefs(dialogsStore);

  onMounted(() => {
    //if dialog is opened block possibility ro resize virtual scrolling recordings history
    allowRecordingsHistoryScreenResize.value = false;
  });

  onBeforeUnmount(() => {
    allowRecordingsHistoryScreenResize.value = true;
  });

  const router = useRouter();
  const isVideoLoaded = ref(false);
  const componentKey = ref(0);
  const videoJsHeight = ref(400);
  const dialogWidth = ref(600);

  watch(
    () => props.modelValue,
    (val) => {
      if (val) {
        nextTick(() => {
          stopPlayingVideos();
        });
      }
    }
  );

  const stopPlayingVideos = () => {
    const videos = document.querySelectorAll("video");

    videos.forEach((video) => {
      if (!video.paused) {
        video.pause();
      }
    });
  };

  const downloadVideo = async () => {
    emit("downloadRecording");
  };

  const shareVideo = async () => {
    if (isMobile.value) {
      nativeShare(props.video.name, { url: props.video?.url });
    } else {
      router.push({
        name: Routes.SHARE.children.SETTINGS.name,
        params: { id: props.video.id },
      });
    }
  };

  const videoLoaded = (videoMetadata: { width: number; height: number }) => {
    let newHeight = 0;

    //32 = 2 x internal dialog padding
    const maxDialogContentWidthOnDesktop = dialogWidth.value - 32;
    const maxVideoHeight = 600;

    const minDialogContentWidthOnMobile = 325;
    //for vertical videos on mobile:
    const minVideoWidthToShowFullControlBar = 250;

    if (videoMetadata.height === videoMetadata.width && isMobile.value) {
      newHeight = minDialogContentWidthOnMobile;
    } else if (videoMetadata.height > videoMetadata.width) {

      newHeight = isMobile.value
        ? (minVideoWidthToShowFullControlBar * videoMetadata.height) / videoMetadata.width
        : maxVideoHeight;
    } else {
      const dialogContentWidth = isMobile.value ? minDialogContentWidthOnMobile : maxDialogContentWidthOnDesktop;

      newHeight = (dialogContentWidth * videoMetadata.height) / videoMetadata.width;
    }

    if (newHeight !== videoJsHeight.value) {
      videoJsHeight.value = newHeight;
      componentKey.value++;
    }

    setTimeout(() => {
      isVideoLoaded.value = true;
    }, 500);
  };
</script>

<style lang="scss" scoped>
.video {
  border-radius: 16px;
  max-height: calc(75vh - 66px);
  width: 600px;

  &--portrait {
    max-width: 448px;
  }

  &--mobile {
    max-width: 100%;
  }
}

.actions {
  gap: 12px;

  &__btn {
    flex: 1;
  }
}
</style>

<style lang="scss">
.video-dialog {
  .dialog__content {
    align-items: center;
    display: flex;
    flex-flow: column;
  }
}
</style>
