import * as Sentry from "@sentry/vue";
import { defaults } from "../data/userStorage";
import { defineStore } from "pinia";
import { getClientData } from "../services/account.service";
import { getSubscriptionLimit } from "../services/checkout.service";
import { useGtm } from "@gtm-support/vue-gtm";
import { useStorage } from "@vueuse/core";
import {
  CLIENT_ID,
  type LoginResponse,
  REFRESH_TOKEN,
  TERMS_CONSENT,
  TOKEN,
} from "../types/login.types";
import {
  getClientPortraits,
} from "../services/synthetics.service";
import { getLipsyncModel, getLipsyncModels } from "../services/lipsync.service";
import {
  getAllPhotoTwinModels,
  getPhotoTwinModelData,
} from "../services/photoTwins.service";
import { isLogged, tokenClientId } from "../api/core.api";
import { LIMITS, Themes } from "../types/other.types";
import type {
  SubscriptionPromo,
  SubscriptionTypes,
} from "../types/checkout.types";
import type { LipsyncStatus } from "../types/synthetic.types";
import {
  ModelType,
  type PhotoTwinModel,
  type SyntheticModel,
  type SyntheticPortrait,
  SyntheticStatus,
} from "../types/synthetic.types";
import { getPromptGalleryItems } from "../services/photoTwins.service";

enum VoiceAccessTypes {
  FREE = "free",
  PREMIUM = "premium",
}

export const useUserStore = defineStore({
  id: "account",
  state: () => ({
    //user account restrictions
    voiceAccess: VoiceAccessTypes.FREE,
    variousFormatsOutput: false,
    ttsStreaming: false,
    voiceCloning: true,
    clonedVoicesLimit: 1,
    audioRecordingsLimit: LIMITS.DEFAULT_AUDIO_RECORDINGS_LIMIT,
    videoRecordingsLimit: LIMITS.DEFAULT_VIDEO_RECORDINGS_LIMIT,
    uploadedVideoFileSizeMb: LIMITS.DEFAULT_UPLOADED_VIDEO_FILE_SIZE,
    uploadedAudioFileSize: LIMITS.DEFAULT_UPLOADED_AUDIO_FILE_SIZE,
    uploadedPortraitsLimit: LIMITS.DEFAULT_UPLOADED_PORTRAITS_LIMIT,
    listOfSyntheticModels: [] as Array<SyntheticModel>,
    subscriberDiscount: false,
    ttsCharactersLimit: LIMITS.DEFAULT_TTS_CHARS_LIMIT,
    ttsCharactersLimitInTextToVideo:
      LIMITS.DEFAULT_TTS_CHARS_LIMIT_TEXT_TO_VIDEO,
    stsAudioSecondsLengthLimit: LIMITS.DEFAULT_STS_AUDIO_LIMIT_SECONDS,
    videoSecondsLengthLimit: 0,
    zeroshotVideoDurationLimitSeconds: 0,
    zeroshotVideoDurationMinSeconds: 0,
    zeroshotAudioDurationLimitSeconds: 0,
    videoWatermark: true,
    syntheticGenerationAccess: false,
    syntheticsGenerationMonthlyLimit: 0,
    syntheticsGenerationAccess: true,
    fineTunedAiTwinRequest: true,
    fineTunedAiTwinUsage: false,
    fineTunedVoiceCloneRequest: true,
    fineTunedVoiceCloneUsage: false,
    tokensPerMonth: 600,

    //user global app settings
    ui_local_data: {} as any,

    //user account ballance
    accountBallance: "",

    //loading items
    allLimitsDataLoaded: false,
    isLoadingLimits: false,
    isLoadingPictures: true,
    isLoadingAccountBallance: false,
    isLoadingPromptGallery: true,
    portraitsLastUpdatedAt: undefined as Date | undefined,

    //user account details
    clientEmail: "",
    clientKey: "",
    createdAt: "",

    //user account subscription details
    subscriptionOption: null as SubscriptionTypes | null,
    subscriptionPriceUSD: "",
    subscriptionUntil: "",
    subscriptionPromo: null as SubscriptionPromo | null,
    subscriptionCancel: false,

    //user specific portraits
    listOfGalleryPictures: [] as Array<SyntheticPortrait>,
    listOfPhotoTwinModels: [] as Array<PhotoTwinModel>,
    loadingPhotoTwinModels: true,
    promptGalleryItems: [] as Array<any>,

    //pricing
    audioCostPerUnit: "",
    billableUnitDurationS: "",
    dubbingCostPerUnit: "",
    photoTwinModelCost: "",
    photoTwinPhotoCost: "",
    videoExpressCostPerUnit: "",
    videoHQCostPerUnit: "",
  }),
  actions: {
    async getUserData() {
      this.isLoadingAccountBallance = true;

      const clientId = tokenClientId();

      try {
        return await getClientData(clientId);
      } catch (e: any) {
        console.error(e);
      } finally {
        this.isLoadingAccountBallance = false;
      }
    },

    setUserAvatar(avatarUrl: string) {
      this.ui_local_data.googleAvatar = avatarUrl;
    },

    setPortraitStudioAccess(access: boolean) {
      this.ui_local_data.portraitStudioAccess = access;
    },

    setMultiTextAccess(access: boolean) {
      this.ui_local_data.multiTextAccess = access;
    },

    setVideoTranslationAccess(access: boolean) {
      this.ui_local_data.videoTranslationAccess = access;
    },

    sendUserDataForIdentification() {
      window.dataLayer?.push({
        event: "identify",
        email: this.userEmail,
        signed_in: this.createdAt,
      });
    },

    async updateBallance() {
      const resp = await this.getUserData();

      this.accountBallance = resp?.balance;
    },

    async updateSubscription() {
      const resp = await this.getUserData();

      if (resp) {
        this.subscriptionOption = resp.subscriptionTier || "";
        this.subscriptionUntil = resp.subscriptionUntil || "";
        (this.subscriptionPriceUSD = resp.subscriptionPriceUSD || ""),
          (this.subscriptionPromo = resp.subscriptionPromo || "");
        this.subscriptionCancel = resp.subscriptionWillCancel || false;
        this.accountBallance = resp?.balance;
      }
    },

    async getUserSubscriptionDetails() {
      const gtm = useGtm();

      try {
        const clientId = tokenClientId();
        const resp = await this.getUserData();

        if (resp) {
          this.clientKey = resp.key;
          this.clientEmail = resp.email;
          this.createdAt = resp.createdAt;
          this.subscriptionOption = resp.subscriptionTier || "";
          this.subscriptionUntil = resp.subscriptionUntil || "";
          this.subscriptionPromo = resp.subscriptionPromo || "";
          (this.subscriptionPriceUSD = resp.subscriptionPriceUSD || ""),
            (this.subscriptionCancel = resp.subscriptionWillCancel || false);
          this.accountBallance = resp?.balance;

          if (gtm?.enabled()) {
            this.sendUserDataForIdentification();
          }
          this.setPortraitStudioAccess(true);
          this.setMultiTextAccess(
            resp.featureFlags?.includes("multi-video-creator") || false
          );
          this.setVideoTranslationAccess(
            resp.featureFlags?.includes("video-translations") || false
          );
          this.setAnalitycsCookie(this.userEmail);
          Sentry.setUser({ email: this.userEmail, id: clientId });
          this.setClientEmail(this.userEmail);
        }
      } catch (e: any) {
        console.error(e);
      }
    },

    async getSubscriptionLimits() {
      if (tokenClientId()) {
        this.isLoadingLimits = true;
        try {
          const resp = await getSubscriptionLimit();

          this.stsAudioSecondsLengthLimit = resp.access.vcAudioLimitSeconds;
          this.ttsCharactersLimit = resp.access.ttsCharacterLimitChars;
          this.clonedVoicesLimit = resp.access.clonedVoicesLimit;
          this.audioRecordingsLimit = resp.access.audioRecordingsLimit;
          this.videoRecordingsLimit = resp.access.videoRecordingsLimit;
          this.syntheticGenerationAccess = resp.access.syntheticGenerationAccess;
          this.videoSecondsLengthLimit = resp.access.videoDurationLimitSeconds;
          this.zeroshotVideoDurationLimitSeconds = resp.access.zeroshotVideoDurationLimitSeconds;
          this.zeroshotVideoDurationMinSeconds = resp.access.zeroshotVideoDurationMinSeconds;
          this.zeroshotAudioDurationLimitSeconds = resp.access.zeroshotAudioDurationLimitSeconds;
          this.subscriberDiscount = resp.access.subscriberDiscount;
          this.videoWatermark = resp.access.videoWatermark;
          this.syntheticsGenerationMonthlyLimit = resp.access.syntheticsGenerationMonthlyLimit;
          this.syntheticsGenerationAccess = resp.access.syntheticsGenerationAccess;
          this.uploadedPortraitsLimit = resp.access.uploadedPortraitsLimit;

          this.audioCostPerUnit = resp.pricing.audioCostPerUnit;
          this.billableUnitDurationS = resp.pricing.billableUnitDurationS;
          this.dubbingCostPerUnit = resp.pricing.dubbingCostPerUnit;
          this.photoTwinModelCost = resp.pricing.photoTwinModelCost;
          this.photoTwinPhotoCost = resp.pricing.photoTwinPhotoCost;
          this.videoExpressCostPerUnit = resp.pricing.videoExpressCostPerUnit;
          this.videoHQCostPerUnit = resp.pricing.videoHQCostPerUnit;

          //@todo: handle when API ready
          this.uploadedAudioFileSize = 150;
          this.uploadedVideoFileSizeMb = 500;
          //this.ttsCharactersLimitInTextToVideo = 2500; this should be here but now is in getter, because not implemented in backend yet
        } catch (e: any) {
          console.error(e);
        } finally {
          this.isLoadingLimits = false;
        }
      }
    },

    async setClientSession(session: LoginResponse) {
      this.ui_local_data = useStorage(
        `ui_data_${session.client.id}`,
        defaults,
        localStorage,
        { mergeDefaults: true } //new added defaults will be merged automatically with local stoarge
      );

      this.setJwtSession({
        token: session.token,
        refreshToken: session.refreshToken,
      });

      this.setPortraitStudioAccess(true);

      this.setMultiTextAccess(
        session.client.featureFlags?.includes("multi-video-creator") || false
      );
      this.setVideoTranslationAccess(
        session.client.featureFlags?.includes("video-translations") || false
      );
      if (session.client.avatarUrl) {
        this.setUserAvatar(session.client.avatarUrl);
      }
      await this.loadUserDetails();
    },

    async loadUserDetails() {
      if (!this.allLimitsDataLoaded) {
        this.allLimitsDataLoaded = true;
        await this.getSubscriptionLimits();
        await this.getUserSubscriptionDetails();
      }

      if (this.ui_local_data.portraitStudioAccess) {
        await this.fetchGalleryPictures();
        await this.fetchSyntheticModels();
        await this.fetchPhotoTwinModels();
        await this.loadPromptGallery();
      }
    },

    async fetchGalleryPictures() {
      try {
        this.isLoadingPictures = true;
        this.listOfGalleryPictures = await getClientPortraits();
      } catch (e: any) {
        console.error(e);
      } finally {
        this.isLoadingPictures = false;
      }
    },
    async loadPromptGallery() {
      try {
        this.isLoadingPromptGallery = true;
        this.promptGalleryItems = await getPromptGalleryItems();
      } catch (e: any) {
        console.error(e);
      } finally {
        this.isLoadingPromptGallery = false;
      }
    },

    cleanUp() {
      this.subscriptionOption = null;
      this.portraitsLastUpdatedAt = undefined;

      this.listOfSyntheticModels = [];
      this.listOfGalleryPictures = [];
      this.promptGalleryItems = [];
      this.allLimitsDataLoaded = false;

      //@todo - verify if this is required
      this.ttsCharactersLimit = LIMITS.DEFAULT_TTS_CHARS_LIMIT;
      this.stsAudioSecondsLengthLimit = LIMITS.DEFAULT_STS_AUDIO_LIMIT_SECONDS;
      this.audioRecordingsLimit = LIMITS.DEFAULT_AUDIO_RECORDINGS_LIMIT;
      this.videoRecordingsLimit = LIMITS.DEFAULT_VIDEO_RECORDINGS_LIMIT;
      this.clonedVoicesLimit = LIMITS.DEFAULT_CLONED_VOICES_LIMIT;

      this.clientEmail = "";
      this.setUserAvatar("");

      console.log("!!!before remove item token!!!");
      console.log("inside cleanup");

      localStorage.removeItem(TOKEN);
      localStorage.removeItem(REFRESH_TOKEN);
      localStorage.removeItem(CLIENT_ID);

      Sentry.setUser(null);
    },

    closeNavGroup(group: string) {
      this.ui_local_data.openedNavGroups =
        this.ui_local_data.openedNavGroups.filter(
          (navGroup: string) => navGroup !== group
        );
    },
    openNavGroup(group: string) {
      if (
        !this.ui_local_data.openedNavGroups.find(
          (navGroup: string) => navGroup === group
        )
      ) {
        this.ui_local_data.openedNavGroups.push(group);
      }
    },
    refreshUserActiveTheme() {
      // this is required for scenario when uset is changinsg theme light/dark in system preferences
      if (this.ui_local_data.theme === Themes.SYSTEM) {
        this.ui_local_data.theme = "";
        this.ui_local_data.theme = Themes.SYSTEM;
      }
    },

    addSyntheticDraft(potrait: SyntheticPortrait) {
      this.listOfGalleryPictures.unshift(potrait);
    },
    addLipsyncModel(potrait: SyntheticModel) {
      this.listOfSyntheticModels.unshift(potrait);
    },
    addPhotoModel(model: PhotoTwinModel) {
      this.listOfPhotoTwinModels.unshift(model);
    },
    updateLipsyncModelStatus(id: number, status: LipsyncStatus) {
      const draftIdx = this.listOfSyntheticModels.findIndex(
        (el) => el.id === id
      );

      if (draftIdx > -1) {
        this.listOfSyntheticModels[draftIdx].status = status;
      }
    },
    async updateZeroShotPicture(id: number) {
      const el = await getLipsyncModel(id);

      const draftIdx = this.listOfSyntheticModels.findIndex(
        (el) => el.id === id
      );

      if (draftIdx > -1) {
        this.listOfSyntheticModels[draftIdx] = el;
      }
    },
    updateSyntheticDraftStatus(id: number, status: SyntheticStatus) {
      const draftIdx = this.listOfGalleryPictures.findIndex(
        (el) => el.id === id
      );

      if (draftIdx > -1) {
        this.listOfGalleryPictures[draftIdx].status = status;
      }
    },
    updateSyntheticDraftVariantUrls(id: number, urls: []) {
      const draftIdx = this.listOfGalleryPictures.findIndex(
        (el) => el.id === id
      );

      if (draftIdx > -1) {
        this.listOfGalleryPictures[draftIdx].variantUrls = urls;
      }
    },
    replaceGalleryPicture(portrait: SyntheticPortrait) {
      const existingPictureIndex = this.listOfGalleryPictures.findIndex(
        (el) => el.id === portrait.id
      );

      if (existingPictureIndex > -1) {
        this.listOfGalleryPictures[existingPictureIndex] = portrait;
      }
    },

    updateLipsyncModel(model: SyntheticModel) {
      const existingPictureIndex = this.listOfSyntheticModels.findIndex(
        (el) => el.id === model.id
      );

      if (existingPictureIndex > -1) {
        this.listOfSyntheticModels[existingPictureIndex] = model;
      }
    },

    async changePhotoTwinModelData(model: PhotoTwinModel) {
      const existingPictureIndex = this.listOfPhotoTwinModels.findIndex(
        (el) => el.id === model.id
      );

      if (existingPictureIndex > -1) {
        this.listOfPhotoTwinModels[existingPictureIndex] = model;
      }
    },

    async updatePhotoModel(id: number) {
      console.log("update photo model");

      const el = await getPhotoTwinModelData(id);

      const existingPictureIndex = this.listOfPhotoTwinModels.findIndex(
        (el) => el.id === id
      );

      if (existingPictureIndex > -1) {
        this.listOfPhotoTwinModels[existingPictureIndex] = el;
      }
    },

    setAnalitycsCookie(userEmail: string) {
      if (isLogged() && userEmail) {
        const emailType =
          userEmail.includes("@charactr.com") ||
          userEmail.includes("@gemelo.ai")
            ? "internal"
            : "external";
        const domain = window.location.hostname.replace("app", "");

        document.cookie = `user_type=${emailType}; max-age=86400; domain=${domain};`;
      }
    },

    setClientEmail(clientEmail: string) {
      this.clientEmail = clientEmail;
    },

    setJwtSession(jwtDetails: { token: string; refreshToken: string }) {
      localStorage.setItem(TOKEN, jwtDetails.token);
      localStorage.setItem(REFRESH_TOKEN, jwtDetails.refreshToken);
    },

    setUserFeatures(data: LoginResponse, clientId: number) {
      localStorage.setItem(
        `${TERMS_CONSENT}_${clientId}`,
        String(data.mainConsentValid)
      );

      this.setPortraitStudioAccess(true);
      this.setMultiTextAccess(
        data.client.featureFlags?.includes("multi-video-creator") || false
      );
      this.setVideoTranslationAccess(
        data.client.featureFlags?.includes("video-translations") || false
      );
      this.fetchSyntheticModels();
    },

    async fetchSyntheticModels() {
      if (
        !this.listOfSyntheticModels.length &&
        this.ui_local_data.portraitStudioAccess
      ) {
        try {
          this.listOfSyntheticModels = await getLipsyncModels();
        } catch (e: any) {
          console.error(e);
        }
      }
    },

    async fetchPhotoTwinModels() {
      this.loadingPhotoTwinModels = true;
      if (
        !this.listOfPhotoTwinModels.length &&
        this.ui_local_data.portraitStudioAccess
      ) {
        try {
          this.listOfPhotoTwinModels = await getAllPhotoTwinModels();
        } catch (e: any) {
          console.error(e);
        } finally {
          this.loadingPhotoTwinModels = false;
        }
      }
      this.loadingPhotoTwinModels = false;
    },
  },
  getters: {
    userEmail(state) {
      return state.clientEmail;
    },
    userKey(state) {
      return state.clientKey;
    },
    activeTheme: (state) => {
      //in case when you are logged out we don't have an access to recent clientId - localStorage client data are connected with client id
      const localStorageDataAvailable =
        Object.keys(state.ui_local_data).length > 0;

      const isSystemThemeSelected =
        state.ui_local_data.theme === Themes.SYSTEM ||
        localStorage.getItem("ui_recentGlobalTheme") === Themes.SYSTEM;

      if (isSystemThemeSelected) {
        if (
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
        )
          return Themes.DARK;
        if (
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: light)").matches
        )
          return Themes.LIGHT;
      }

      if (localStorageDataAvailable) {
        localStorage.setItem("ui_recentGlobalTheme", state.ui_local_data.theme);
        return state.ui_local_data.theme;
      }
      return localStorage.getItem("ui_recentGlobalTheme") || Themes.LIGHT;
    },
    userAccountBallance: (state) => {
      return state.accountBallance;
    },
    videoSecondsMaxLength: (state) => {
      return state.videoSecondsLengthLimit;
    },
    zeroShotCreationMin: (state) => {
      return state.zeroshotVideoDurationMinSeconds;
    },
    zeroShotCreationMax: (state) => {
      return state.zeroshotVideoDurationLimitSeconds;
    },
    zeroShotAudioMaxUsage: (state) => {
      return state.zeroshotAudioDurationLimitSeconds;
    },
    stsAudioSecondsMaxLength: (state) => {
      return state.stsAudioSecondsLengthLimit;
    },
    ttsCharactersMaxLength: (state) => {
      return state.ttsCharactersLimit;
    },
    ttsCharactersLimitInTextToVideoMaxLength: (state) => {
      return state.subscriptionOption ? 2500 : 300;
    },
    audioRecordingsLimitNumber: (state) => {
      return state.audioRecordingsLimit;
    },
    videoRecordingsLimitNumber: (state) => {
      return state.videoRecordingsLimit;
    },
    clonedVoicesLimitNumber: (state) => {
      return state.clonedVoicesLimit;
    },
    isUnlimitedVideoAccess: (state) => {
      return (
        !state.isLoadingLimits &&
        state.videoRecordingsLimit === LIMITS.UNLIMITED
      );
    },
    isUnlimitedAudioAccess: (state) => {
      return (
        !state.isLoadingLimits &&
        state.audioRecordingsLimit === LIMITS.UNLIMITED
      );
    },
    isUnlimitedVoiceCloningAccess: (state) => {
      return (
        !state.isLoadingLimits && state.clonedVoicesLimit === LIMITS.UNLIMITED
      );
    },
    isUnlimitedPortraitsUpload: (state) => {
      return (
        !state.isLoadingLimits &&
        state.uploadedPortraitsLimit === LIMITS.UNLIMITED
      );
    },

    readyGalleryPictures(state) {
      return state.listOfGalleryPictures.filter(
        (el) => el.status === SyntheticStatus.READY
      );
    },

    userDraftGalleryPictures(state) {
      return state.listOfGalleryPictures.filter(
        (el) => !el.isBuiltin && el.status !== SyntheticStatus.READY
      );
    },

    userReadyGalleryPictures(state) {
      return state.listOfGalleryPictures.filter(
        (el) => !el.isBuiltin && el.status === SyntheticStatus.READY
      );
    },

    systemGalleryPictures(state) {
      return state.listOfGalleryPictures.filter((el) => el.isBuiltin);
    },

    userPortraits(state) {
      return state.listOfGalleryPictures.filter(
        (p: SyntheticPortrait) => !p.isBuiltin
      );
    },

    userLipsyncModels(state) {
      return state.listOfSyntheticModels.filter(
        (p: SyntheticModel) => p.type === ModelType.NORMAL && !p.isBuiltin
      );
    },

    systemLipsyncModels(state) {
      return state.listOfSyntheticModels.filter(
        (p: SyntheticModel) => p.type === ModelType.NORMAL && p.isBuiltin
      );
    },

    userZeroShotModels(state) {
      return state.listOfSyntheticModels.filter(
        (p: SyntheticModel) => p.type === ModelType.ZEROSHOT && !p.isBuiltin
      );
    },

    systemZeroShotModels(state) {
      return state.listOfSyntheticModels.filter(
        (p: SyntheticModel) => p.type === ModelType.ZEROSHOT && p.isBuiltin
      );
    },

    userPhotoTwinModels(state) {
      return state.listOfPhotoTwinModels.filter(
        (p: PhotoTwinModel) => !p.isBuiltin
      );
    },

    systemPhotoTwinModels(state) {
      return state.listOfPhotoTwinModels.filter(
        (p: PhotoTwinModel) => p.isBuiltin
      );
    },

    promptItems(state) {
      return state.promptGalleryItems;
    },
  },
});
