export enum AlertModes {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

export enum Themes {
  LIGHT = "lightTheme",
  DARK = "defaultTheme",
  SYSTEM = "systemTheme",
}

export enum RightPanelTabs {
  VOICES = "voices",
  RECORDINGS = "recordings",
}

//here we will add application features to show/hide based on subscription
export interface AppFeatures {
  portraitStudio: boolean;
  videoTranslation: boolean;
}

export type TitleFeature = {
  name: string;
  soon: boolean;
  testId: string,
  title: string;
  desc: string;
  tags: Array<string>;
  image: string;
  video? :string,
  hidden: boolean;
  actionIcon: string;
  actionLabel: string;
  infoDesc: string;
  routeName? :string;
  images?: Array<string>;
  action?(): void;
};

export const LIMITS = {
  UNLIMITED: -1,
  DEFAULT_STS_AUDIO_LIMIT_SECONDS: 20,
  DEFAULT_TTS_CHARS_LIMIT: 300,
  DEFAULT_TTS_CHARS_LIMIT_TEXT_TO_VIDEO: 300,
  DEFAULT_AUDIO_RECORDINGS_LIMIT: 10,
  DEFAULT_VIDEO_RECORDINGS_LIMIT: 3,
  DEFAULT_CLONED_VOICES_LIMIT: 1,
  DEFAULT_UPLOADED_AUDIO_FILE_SIZE: 5,
  DEFAULT_UPLOADED_VIDEO_FILE_SIZE: 50,
  DEFAULT_UPLOADED_PORTRAITS_LIMIT: 1,
  DASHBOARD_RECENT_VIDEOS_TO_SHOW_FREE: 3,
  DASHBOARD_RECENT_VIDEOS_TO_SHOW_PREMIUM: 12,
};

export const UNKNOWN_PORTRAIT_ID = -2;

export enum RightPanelLimitTypes {
  AUDIO_RECORDINGS = "audio_rec",
  VIDEO_RECORDINGS = "video_rec",
  CLONED_VOICES = "cloned_voices"
}

export const RightPanelLimits = {
  [RightPanelLimitTypes.AUDIO_RECORDINGS]: "You have reached the limit of #limit# audio recordings in your Recording History. Upgrade to unlock the full potential.",
  [RightPanelLimitTypes.VIDEO_RECORDINGS]: "You have reached the limit of #limit# videos in your Recording History. Upgrade to unlock the full potential.",
  [RightPanelLimitTypes.CLONED_VOICES]: "You have reached the limit of #limit# cloned voices in Voices Library. Upgrade to unlock the full potential.",
};
