import  { PLAYGROUND_FEATURES, type  PlaygroundFeature } from "./playgroundFeatures";
import { Routes } from "../routes/core.guard";

export type AppFeatureCard = {
  name: string;
  extended?: boolean;
  testId: string;
  description?: string;
  imgDark: string;
  imgLight: string;
  imgAlt?: string;
  routeName?: string;
  routePath?: string;
  hidden?: boolean;
  mode?: PlaygroundFeature;
  title?: string;
  actionButton?: string;
  action?(): void;
};

const voiceCloning: AppFeatureCard = {
  name: "Voice Cloning",
  description: "Clone your voice with a 10-second audio sample",
  imgDark: "/images/home/dark/audioCreatorCloning.png",
  imgLight: "/images/home/light/audioCreatorCloning.png",
  imgAlt: "Voice Cloning",
  testId: "cloning",
  routeName: Routes.VOICE_CLONING.name,
};

// const videoToVideo: AppFeatureCard = {
//   name: "Video to Video",
//   description: "Transform your video into a video of the Gemelo",
//   imgDark: "/images/home/dark/vtv.svg",
//   imgLight: "/images/home/light/vtv.svg",
//   imgAlt: "Video to Video",
//   routeName: Routes.VIDEO_TO_VIDEO.children.CREATE.name,
// };

export const textToSpeech: AppFeatureCard = {
  name: "Text to Speech",
  description: "Transform text into natural AI-generated speech ",
  imgDark: "/images/home/dark/tts.svg",
  imgLight: "/images/home/light/tts.svg",
  imgAlt: "Text to Speech",
  testId: "tts",
  routeName: Routes.TEXT_TO_SPEECH.name,
  mode: PLAYGROUND_FEATURES.TTS,
};

// export const voiceToVoice: AppFeatureCard = {
//   name: "Speech to Speech",
//   description: "Change any voice input into a dynamic AI voice",
//   imgDark: "/images/home/dark/voiceConversion.svg",
//   imgLight: "/images/home/light/voiceConversion.svg",
//   imgAlt: "Speech to Speech",
//   testId: "sts",
//   routeName: Routes.SPEECH_TO_SPEECH.name,
//   mode: PLAYGROUND_FEATURES.STS,
// };

const textToVideo: AppFeatureCard = {
  name: "Text to Video",
  description: "Create a Gemelo video",
  imgDark: "/images/home/dark/textToVideo.png",
  imgLight: "/images/home/light/textToVideo.png",
  imgAlt: "Gemelo to Video",
  testId: "ttv",
  routeName: Routes.VIDEO_CREATOR.children.MAIN.children?.TEXT.name,
};

function addFeature(
  feature: AppFeatureCard,
  target: Array<AppFeatureCard>,
  add: boolean
) {
  if (add) {
    target.push(feature);
  }
}

function buildRestFeatures(
  portraitStudioAccess: boolean
): Array<AppFeatureCard> {
  const appFeatures: Array<AppFeatureCard> = [];

  addFeature(textToVideo, appFeatures, portraitStudioAccess);
  addFeature(voiceCloning, appFeatures, true);
  //addFeature(videoToVideo, appFeatures, features.portraitStudio);
  // addFeature(voiceToVoice, appFeatures, true);
  addFeature(textToSpeech, appFeatures, true);

  // addFeature(videoConversion, appFeatures, true);
  // addFeature(statistics, appFeatures, true);
  // addFeature(apiKeys, appFeatures, true);

  return appFeatures;
}

export function buildHomeFeatures(
  portraitStudioAccess: boolean
): Array<AppFeatureCard> {
  const restFeatures = buildRestFeatures(portraitStudioAccess);

    return restFeatures;

}

export function buildVoiceCloningOptions(
): Array<AppFeatureCard> {
  const appFeatures: Array<AppFeatureCard> = [];

  addFeature(textToSpeech, appFeatures, true);
  // addFeature(voiceToVoice, appFeatures, true);

    return appFeatures;

}

