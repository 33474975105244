export enum VideoStatus {
  UPLOADING = "uploading",
  LIPSYNC_GENERATING = "lipsync",
  LIVEPORTRAIT_GENERATING = "liveportrait",
  READY = "ready",
  ERROR = "error",
  LIPSYNC_QUEUED = "lipsync_queued",
  LIVEPORTRAIT_QUEUED = "liveportrait_queued",
  DUBBING_QUEUED = "dubbing_queued",
  DUBBING_GENERATING = "dubbing",
}

export type SyntheticVideo = {
  id: number;
  clientId: number;
  portraitId: number;
  thumbnailUrl?: string;
  lipsyncId?: number;
  name: string;
  status: VideoStatus;
  type?: string;
  error?: string;
  url: string;
  videoDurationMs: number;
  videoSizeBytes?: number;
  createdAt: string;
  //to check with backend
  sharingActive: boolean;
  shareId: string;

  //for mocked video
  imageLoadingUrl?: string;
};

export enum VideoPlayerVariants {
  LIST = "list",
  GALLERY = "gallery",
  GALLERY_MINI = "gallery-mini",
}
