<template>
  <c-typography
    class="d-block mb-7 text-center"
    data-testid="ai-twin-studio-title"
    variant="subtitle-2-600"
  >Translated Videos
  </c-typography>
  <div class="align-center d-flex justify-center my-2 w-100">
    <c-dropdown-menu
      :disabled="!recordings.length"
      :dropdown-options="sortingCriterias"
      title="Sort by"
    >
      <template #activator="{ isActive }">
        <c-button
          class="mr-2"
          :disabled="!recordings.length"
          icon="ph:sort-ascending"
          mode="outline"
          :open="isActive"
        />
      </template>
    </c-dropdown-menu>

    <c-input
      ref="searchEl"
      v-model="data.search"
      density="compact"
      :disabled="!recordings.length && !data.search"
      mode="search"
      @click.stop
      @focus="searchFocus"
    />
  </div>

  <template v-if="!data.isLoading">
    <c-custom-card
      :class="[
        [isMobile ? 'voices-card--mobile' : 'voices-card--desktop w-100'],
      ]"
      color="dark-bg"
      draggable
      type="primary"
    >
      <template #content>
        <div class="w-100">
          <div>
            <v-virtual-scroll
              ref="videoScroll"
              v-memo="[
                data.search,
                data.origin,
                recordings.length,
                updateRecordingsKey,
                userStore.ui_local_data.translatedVideoSorting,
                unlockedNumberOfGalleryItemsInOneRow,
                scrollerWidth && allowRecordingsHistoryScreenResize,
                scrollerWidth && allowRecordingsHistoryScreenResize,
                scrollerHeight && allowRecordingsHistoryScreenResize,
                windowWidth && allowRecordingsHistoryScreenResize,
                windowHeight && allowRecordingsHistoryScreenResize,
                numOfVideoRecordingsListUpadtes,
              ]"
              :height="recordings.length ? windowHeight : 0"
              :item-height="getItemHeight"
              :items="packedRecordings"
            >
              <template #default="{ item, index }">
                <div
                  :class="[
                    {
                      'gallery pb-2': true,
                      'gallery--mobile': isMobile,
                    },
                  ]"
                >
                  <template
                    v-for="(recording, idx) in item"
                    :key="recording.isCreateNew ? 'create-new' : recording.id"
                  >
                    <c-custom-card
                      v-if="recording.isCreateNew"
                      class="card-add-new d-flex pa-4"
                      color="alpha-bg"
                      :height="isMobile ? '274' : '274'"
                      type="primary"
                      :width="isMobile ? '320' : '390'"
                      @click="data.showCreateDialog = true"
                    >
                      <template #content>
                        <div
                          class="align-center d-flex flex-column h-100 justify-center w-100"
                        >
                          <c-button
                            icon="ph:plus"
                            icon-height="20"
                            mode="highlight"
                            size="x-small"
                          />
                          <c-button mode="highlight-plain" size="default">
                            Translate New
                          </c-button>
                        </div>
                      </template>
                    </c-custom-card>

                    <vtv-player
                      v-else
                      :bottom-border="false"
                      :chip="''"
                      :cut-chip="isMobile"
                      :idx="idx + index * numberOfGalleryVideosInRow"
                      :language="true"
                      :length="recordings.length"
                      :mode="PLAYGROUND_FEATURES.VIDEO_TRANSLATION.name"
                      open-preview-on-click
                      :playing-video-id="data.playingVideoId"
                      :simple="false"
                      :top-border="false"
                      :variant="VideoPlayerVariants.GALLERY"
                      :video="recording"
                      @set-playing-id="setPlayingId"
                    />
                  </template>
                </div>
              </template>
            </v-virtual-scroll>
          </div>
        </div>
      </template>
    </c-custom-card>
  </template>

  <template v-if="!recordings.length && !data.isLoading">
    <div
      :class="[
        'no-data-container align-center d-flex flex-column justify-center',
        { 'pt-40 mb-4 pb-6 px-4': !isMobile && !data.search },
        { 'py-40': !isMobile && data.search },
        { 'py-4 px-2': isMobile },
      ]"
    >
      <template v-if="!data.search">
        <div :class="['d-flex']">
          <div class="position-relative">
            <c-custom-card
              class="card-add-new d-flex pa-4 mx-4"
              color="alpha-bg"
              :height="isMobile ? '274' : '274'"
              type="primary"
              :width="isMobile ? '335' : '390'"
              @click="data.showCreateDialog = true"
            >
              <template #content>
                <div
                  class="align-center d-flex flex-column h-100 justify-center w-100"
                >
                  <c-button
                    icon="ph:plus"
                    icon-height="20"
                    mode="highlight"
                    size="x-small"
                  />
                  <c-button mode="highlight-plain" size="default">
                    Translate New
                  </c-button>
                </div>
              </template>
            </c-custom-card>
          </div>

          <div v-if="!isMobile" class="d-flex flex-column mx-10">
            <c-typography
              class="button-secondary"
              color-class="button-secondary"
              :variant="
                windowWidth < 1100 ? (isMobile ? 'subtitle-1-600' : 'h2') : 'h1'
              "
            >Translate your first video</c-typography
            >

            <img class="align-cat-center create-new-cat mt-4" src="/images/spaceCat.svg" />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="align-center d-flex flex-column text-center">
          <c-typography color-class="copy-primary" variant="h4">{{
            noRecordingsTitle
          }}</c-typography>
          <c-typography color-class="copy-secondary" variant="body-2-400">{{
            noRecordingsText
          }}</c-typography>
        </div>
      </template>
    </div>
  </template>
  <create-new-translation-dialog
    v-model="data.showCreateDialog"
    @success="handleTranslationSuccess"
  />
  <circle-loader v-if="data.isLoading" title="Loading recordings" />
</template>

<script lang="ts" setup>
  import CircleLoader from "@/core/components/Container/CircleLoader.vue";
  import { isMobile } from "@/core/utils/mobile";
  import { Routes } from "@/core/routes/core.guard";
  import { sortByPropertyName } from "@/core/utils/sorting";
  import VtvPlayer from "../../../core/components/RightDrawer/components/Players/VtvPlayer.vue";
  import type { VideoTranslation } from "@/core/types/videoTranslation.types";

  import { PLAYGROUND_FEATURES } from "@/core/data/playgroundFeatures";

  import { useDrawerStore } from "@/core/components/RightDrawer/store";
  import { useUserStore } from "@/core/store/userStore";
  import { useWindowSize } from "@vueuse/core";
  import { analytics, RECORDINGS_HISTORY } from "@/core/utils/analytics";
  import { computed, onBeforeMount, reactive, ref, toRefs, watch } from "vue";

  import { sortingIds } from "@/modules/recordingsHistory/types/history.types";
  import { VideoPlayerVariants } from "@/core/types/video.types";

  // @ts-ignore
  // prettier-ignore
  import {  CButton, CInput, CTypography } from "@charactr/wooper-ui/atoms";
  // @ts-ignore
  // prettier-ignore
  import {  CCustomCard, CDropdownMenu } from "@charactr/wooper-ui/molecules";

  import { useElementSize } from "@vueuse/core";

  import { useStore } from "@/core/store";
  import { useDialogsStore } from "@/core/store/useDialogsStore";
  import CreateNewTranslationDialog from "../components/CreateNewDialog.vue";

  //PROPS & EMITS
  const props = defineProps({
    videoCreator: {
      type: Boolean,
      default: false,
    },
    currentRecordingId: {
      type: Number,
      default: 0,
    },
  });
  const emit = defineEmits(["selectRecording"]);

  //STORE
  const dialogsStore = useDialogsStore();
  const { allowRecordingsHistoryScreenResize } = toRefs(dialogsStore);
  const drawerStore = useDrawerStore();
  const store = useStore();
  const { numOfVideoRecordingsListUpadtes } = toRefs(store);
  const userStore = useUserStore();

  const searchEl = ref();
  const videoScroll = ref();
  const updateRecordingsKey = ref(0);
  const { height: windowHeight, width: windowWidth } = useWindowSize();

  const { width: scrollerWidth, height: scrollerHeight } =
    useElementSize(videoScroll);

  const unlockedNumberOfGalleryItemsInOneRow = computed(() => {
    return (
      allowRecordingsHistoryScreenResize.value && numberOfGalleryVideosInRow.value
    );
  });

  const data = reactive({
    isLoading: false,
    search: "",
    origin: null,
    playingVideoId: "",
    showCreateDialog: false,
  });

  const noRecordingsTitle = computed(() => data.origin
    ? `No "${data.origin}" results`
    : data.search
      ? "Oops!"
      : "No recordings"
  );

  const noRecordingsText = computed(() => data.origin
    ? "Clear this filter to show all recordings."
    : data.search
      ? `No results for ${data.search}`
      : "There are no recordings to show. Start to create now."
  );

  const sortingCriterias = computed(() => [
    {
      name: "Newest First",
      appendIcon: isSortingActive(sortingIds.NEWEST_FIRST) ? "ph:check" : "",
      action: () => {
        setSorting(sortingIds.NEWEST_FIRST);
      },
    },
    {
      name: "Oldest First",
      appendIcon: isSortingActive(sortingIds.OLDEST_FIRST) ? "ph:check" : "",
      action: () => {
        setSorting(sortingIds.OLDEST_FIRST);
      },
    },
    {
      name: "Name (A-Z)",
      appendIcon: isSortingActive(sortingIds.NAME_ASCENDING) ? "ph:check" : "",
      action: () => {
        setSorting(sortingIds.NAME_ASCENDING);
      },
    },
    {
      name: "Name (Z-A)",
      appendIcon: isSortingActive(sortingIds.NAME_DESCENDING) ? "ph:check" : "",
      action: () => {
        setSorting(sortingIds.NAME_DESCENDING);
      },
    },

    {
      name: "Longest First (s)",
      appendIcon: isSortingActive(sortingIds.LONGEST_FIRST) ? "ph:check" : "",
      action: () => {
        setSorting(sortingIds.LONGEST_FIRST);
      },
    },
    {
      name: "Shortest First (s)",
      appendIcon: isSortingActive(sortingIds.SHORTEST_FIRST) ? "ph:check" : "",
      action: () => {
        setSorting(sortingIds.SHORTEST_FIRST);
      },
    },
  ]);

  const getItemHeight = computed(() => {
    return 400;
  });

  const recordingsContainerHeight = computed(() => {
    return "calc(100vh - 288px)";
  });

  const recordings = computed(() => {
    let filteredRecordings = drawerStore.translationRecordings;

    if (data.search) {
      filteredRecordings = filterRecordings(filteredRecordings, "name");
    }
    return sortRecordings(filteredRecordings);
  });

  const { width } = useElementSize(searchEl);

  const numberOfGalleryVideosInRow = computed(() => {
    if (width.value > 0) {
      switch (userStore.ui_local_data.galleryVideoVariant) {
      case VideoPlayerVariants.GALLERY:
        return Math.floor(width.value / 370);
      default:
        return 1;
      }
    } else {
      return 1;
    }
  });

  const packedRecordings = computed(() => {
    const rowSize = numberOfGalleryVideosInRow.value || 1;
    const allRecordings = recordings.value;
    const result = [];

    // First row with Translate New card
    const firstRow = [];

    firstRow.push({ isCreateNew: true }); // Add create new card placeholder

    // Add recordings to first row
    for (let i = 0; i < rowSize - 1 && i < allRecordings.length; i++) {
      firstRow.push(allRecordings[i]);
    }
    result.push(firstRow);

    // Pack remaining recordings
    const remainingRecordings = allRecordings.slice(rowSize - 1);

    for (let i = 0; i < remainingRecordings.length; i += rowSize) {
      result.push(remainingRecordings.slice(i, i + rowSize));
    }

    return result;
  });

  onBeforeMount(async () => {
    loadData();
  });

  const loadVideos = async () => {
    data.isLoading = true;
    await drawerStore.loadTranslations();
    data.isLoading = false;
  };

  const loadData = async () => {
    if (!drawerStore.translationRecordings.length && !props.videoCreator) {
      await loadVideos();
    }
  };

  const searchFocus = () => {
    analytics.sendEvent(
      "recordings_history",
      RECORDINGS_HISTORY.actions.SEARCH_RECORDING_INPUT_SELECT
    );
  };

  const sortRecordings = (recordings: Array<any>) => {
    if (userStore.ui_local_data.translatedVideoSorting) {
      const propSortingName = "name";
      const propSortingLength = "videoDurationMs";

      switch (userStore.ui_local_data.translatedVideoSorting) {
      case sortingIds.NAME_ASCENDING:
        return sortByPropertyName(recordings, propSortingName, "asc");
      case sortingIds.NAME_DESCENDING:
        return sortByPropertyName(recordings, propSortingName, "desc");
      case sortingIds.NEWEST_FIRST:
        return sortByPropertyName(recordings, "createdAt", "desc");
      case sortingIds.OLDEST_FIRST:
        return sortByPropertyName(recordings, "createdAt", "asc");
      case sortingIds.LONGEST_FIRST:
        return sortByPropertyName(recordings, propSortingLength, "desc");
      case sortingIds.SHORTEST_FIRST:
        return sortByPropertyName(recordings, propSortingLength, "asc");
      }
    }
    return sortByPropertyName(recordings, "createdAt", "desc");
  };

  const filterRecordings = (list: Array<any>, prop: string) => {
    if (data.search && prop) {
      const searchQuery = data.search.toLowerCase();

      return list.filter((recording) => {
        if (recording[prop]) {
          return recording[prop].toLowerCase().includes(searchQuery);
        }
        return false;
      });
    }
    return list;
  };

  const isSortingActive = (sorting: sortingIds) => {
    return userStore.ui_local_data.translatedVideoSorting === sorting;
  };

  const setSorting = (sorting: sortingIds) => {
    userStore.ui_local_data.translatedVideoSorting = sorting;
  };

  const setPlayingId = (val: string) => {
    data.playingVideoId = val;
  };

  const handleTranslationSuccess = (translation: VideoTranslation) => {
    drawerStore.prependTranslation(translation);
  };

  watch(() => drawerStore.translationRecordings, () => {
    updateRecordingsKey.value++;
  }, { deep: true });

</script>

<style scoped lang="scss">
.recordings-wrapper {
  border: 1px solid rgb(var(--v-theme-light-frame));
  border-radius: 8px;
  max-height: v-bind(recordingsContainerHeight);
  overflow: auto;
}

.pt-40 {
  padding-top: 40px;
}

.gallery {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(v-bind(numberOfGalleryVideosInRow), 390px);
  justify-content: center;

  &--mobile {
    grid-template-columns: repeat(v-bind(numberOfGalleryVideosInRow), 320px);
  }
}

.card-add-new {
  border: 1px dashed rgb(var(--v-theme-dark-frame));
  border-radius: 12px !important;
  box-shadow: 0 5px 15px 0 rgb(0 0 0 / 8%);
  cursor: pointer;

  &:hover {
    background: rgb(var(--v-theme-highlight-aphla)) !important;
    border: 1px solid rgb(var(--v-theme-button-primary));
  }
}

.button-secondary {
  color: rgb(var(--v-theme-button-secondary)) !important;
}

.mb-250 {
  margin-bottom: 250px;
}

.create-new {
  &-arrow {
    transform: rotate(30.652deg);
    width: 250px;
    pointer-events: none;
  }

  &-pictures-wrapper {
    gap: 20px;
    top: 210px;
    pointer-events: none;

    &--mobile {
      left: 50px;
      top: 130px;
    }
  }

  &-cat {
    pointer-events: none;
  }
}

@media (min-width: 850px) {
  .create-new {
    &-pictures-wrapper {
      left: 50px;
      top: 180px;
    }
  }
}

@media (min-width: 1100px) {
  .create-new {
    &-arrow {
      transform: rotate(18.652deg);
      width: 350px;
    }

    &-pictures-wrapper {
      gap: 50px;
      left: 100px;
      top: 220px;
    }
  }
}

@media (min-width: 1311px) {
  .create-new {
    &-arrow {
      transform: rotate(7.652deg);
      width: 350px;
    }

    &-pictures-wrapper {
      gap: 100px;
      left: 200px;
      top: 160px;
    }

    &-cat {
      width: 230px;
    }
  }
}

@media (min-width: 1900px) {
  .create-new {
    &-arrow {
      transform: rotate(2.652deg);
      width: 430px;
    }

    &-pictures-wrapper {
      gap: 100px;
      left: 250px;
      top: 160px;
    }
  }
}

.no-data-container {
  background: rgb(var(--v-theme-aphla-bg));
  border-radius: 12px;
}

.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.create-new-cat {
  pointer-events: none;
  width: 190px;
}

.align-cat-center {
  align-self: center;
  margin-right: 20px;
}
</style>
