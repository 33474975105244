<template>
  <template v-if="data.mode !== PLAYGROUND_FEATURES.VOICE_CLONING.name">
    <c-button
      v-if="!isMobile"
      :disabled="props.voice.disabled"
      height="40"
      mode="highlight-plain"
      no-padding
      @click="testVoice(voice, PLAYGROUND_FEATURES.TTS)"
    >
      Use Voice
    </c-button>

    <c-dropdown-menu
      v-else
      :disabled="props.voice.disabled"
      :dropdown-options="getDropdownOptions(props.voice)"
      width="176"
    >
      <template #activator>
        <c-button
          class="d-inline-block"
          :disabled="props.voice.disabled"
          height="20"
          icon="ph:dots-three-outline-vertical-fill"
          icon-height="20"
          mode="secondary"
          width="20"
        />
      </template>
    </c-dropdown-menu>
  </template>
  <div v-else class="py-2" :class="[isMobile ? 'ml-1' : 'ml-10']">

    <c-button
      v-if="!isMobile"
      :disabled="!userStore.subscriptionOption"
      height="40"
      mode="highlight-plain"
      no-padding
      @click="testVoice(voice, PLAYGROUND_FEATURES.TTS)"
    >
      Use Voice
    </c-button>
    <c-dropdown-menu
      v-else
      :dropdown-options="getClonedDropdownOptions(voice)"
      width="185"
    >
      <template #activator>
        <c-button
          v-if="isMobile"
          class="d-inline-block"
          height="20"
          icon="ph:dots-three-outline-vertical-fill"
          icon-height="20"
          mode="secondary"
          width="20"
        />
      </template>
    </c-dropdown-menu>
  </div>
</template>

<script setup lang="ts">
  import { isMobile } from "@/core/utils/mobile";
  import { useRouter } from "vue-router";
  import type { Voice } from "@/core/types/voice.types";

  import { useStore } from "@/core/store";
  import { useUserStore } from "@/core/store/userStore";

  import { analytics, VOICES } from "@/core/utils/analytics";
  import { computed, onMounted, type PropType, reactive, watch } from "vue";
  import {
    PLAYGROUND_FEATURES,
    type PlaygroundFeature,
  } from "@/core/data/playgroundFeatures";

  // @ts-ignore
  import { CDropdownMenu } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  import { CButton, CIcon, CTypography } from "@charactr/wooper-ui/atoms";

  import { useVoicesStore } from "@/core/store/useSelectedVoicesStore";
  const props = defineProps({
    voice: {
      type: Object as PropType<Voice>,
      default: null,
    },
  });

  const emit = defineEmits(["delete", "edit"]);

  const { updateVoice } = useVoicesStore();

  const router = useRouter();
  const store = useStore();
  const userStore = useUserStore();

  const data = reactive({
    mode: "" as PlaygroundFeature["name"],
  });

  onMounted(() => {
    data.mode = router.currentRoute.value.meta.mode as PlaygroundFeature["name"];
  });

  const voiceMode = computed(() => {
    return router.currentRoute.value.meta.mode;
  });

  watch(
    () => voiceMode.value,
    (newVal) => {
      data.mode = newVal as PlaygroundFeature["name"];
    }
  );

  const testVoice = (val: Voice, playground: PlaygroundFeature) => {
    analytics.sendEvent("voices", VOICES.actions.TRY, { voice_id: val.id });
    updateVoice(val, playground.name);
    router.push({ name: playground.routeName, query: { selected: "true" } });
  };

  const getDropdownOptions = (voice: Voice) => {
    return [
      {
        name: "Text to speech",
        icon: PLAYGROUND_FEATURES.TTS.icon,
        action: () => testVoice(voice, PLAYGROUND_FEATURES.TTS),
      },
      // {
      //   name: "Speech to speech",
      //   icon: PLAYGROUND_FEATURES.STS.icon,
      //   action: () => testVoice(voice, PLAYGROUND_FEATURES.STS),
      // },
    ];
  };

  const getClonedDropdownOptions = (voice: Voice) => {
    const playgrounds = getDropdownOptions(voice);

    if (isMobile.value) {
      playgrounds.push({
        name: "Delete",
        icon: "ph:trash-simple",
        action: () => {
          emit("delete");
        },
      });
      playgrounds.push({
        name: "Edit",
        icon: "ph:pencil-line",
        action: () => {
          emit("edit");
        },
      });
    }

    return playgrounds;
  };
</script>

<style lang="scss" scoped>
.rating-wrapper {
  align-self: center;
  min-width: 70px;
}

.cursor-pointer {
  cursor: pointer;
}

.extra-padding {
  padding-left: 38px !important;
}

.menu {
  cursor: pointer;
  transition: all 0.4s ease;

  &--expanded {
    transform: rotate(180deg);
  }
}
</style>
