
import { Routes } from "@/core/routes/core.guard";
import TranslatedVideos from "../pages/TranslatedVideos.vue";

export default [
  {
    name: Routes.VIDEO_TRANSLATION.name,
    path: Routes.VIDEO_TRANSLATION.path,
    redirect: Routes.RECORDINGS_HISTORY.children.VIDEO_TRANSLATION.name,
    component: TranslatedVideos,
    meta: {
      requiresAuth: true,
      isConsumingBallance: true,
      isMobileFullScreenView: true,
      centered: true,
      requiresVideoTranslationAccess: true,
    },
  },
  {
    name: Routes.RECORDINGS_HISTORY.children.VIDEO_TRANSLATION.name,
    path: Routes.RECORDINGS_HISTORY.children.VIDEO_TRANSLATION.path,
    component: TranslatedVideos,
    meta: {
      requiresVideoTranslationAccess: true,
    },
  },
];
