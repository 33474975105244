import { apiV3Url } from "@/core/utils/api";
import coreClient from "@/core/api/core.api";
import { toRefs } from "vue";
import { useProgressStore } from "../store/useProgressStore";
import { VIDEO_PROCESSING } from "../types/file-processing.types";
import type { VideoTranslation, VideoTranslationPayload } from "@/core/types/videoTranslation.types";

function defineUploadConfig() {
  const progressStore = useProgressStore();
  const { dataProcessingState, dataProcessingPercentage } = toRefs(progressStore);

  dataProcessingPercentage.value = 0;

  return {
    onUploadProgress: function (progressEvent: ProgressEvent) {
      dataProcessingPercentage.value = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      if (dataProcessingPercentage.value === 100) {
        dataProcessingState.value = VIDEO_PROCESSING;
      }
    },
    headers: {
        "X-File-Ms": 0,
      },
  };
}

export async function createVideoTranslation(payload: VideoTranslationPayload): Promise<VideoTranslation> {
    const formData = new FormData();

    formData.append("json", JSON.stringify({
      outputLanguage: payload.outputLanguage,
      lipsync: payload.lipsync,
    }));
    formData.append("video", payload.video);

    const config = defineUploadConfig();

    config.headers["X-File-Ms"] = Math.ceil(payload.videoDurationMs);

    const url = apiV3Url(
      `/video-translations?media-type=${payload.video.type.split(";")[0]}`
    );

    const response = await coreClient.post(url, formData, config);

    return response.data;
  }

export async function getVideoTranslations(): Promise<VideoTranslation[]> {
  const url = apiV3Url("/video-translations");
  const response = await coreClient.get(url);

  return response.data;
}

export async function getVideoTranslation(id: string): Promise<VideoTranslation> {
  const url = apiV3Url(`/video-translations/video_translation_${id}`);
  const response = await coreClient.get(url);

  return response.data;
}

export async function updateVideoTranslationName(id: string, name: string): Promise<VideoTranslation> {
  const url = apiV3Url(`/video-translations/${id}`);

  const response = await coreClient.patch(url, {
    name,
  });

  return response.data;
}

export async function deleteVideoTranslation(id: string): Promise<void> {
  const url = apiV3Url(`/video-translations/${id}`);

  await coreClient.delete(url);
}
