// c-list-item alias doesnt work
<template>
  <div
    class="nav__links pa-4"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <template v-for="el in menuItems" :key="el.category">
      <template
        v-if="el.category && el.items.filter((elItem) => elItem.show).length"
      >
        <div v-if="!isRail" class="mb-1">
          <c-typography
            class="category d-flex"
            color-class="universal-gray"
            variant="body-3-400"
          >{{ el.category }}</c-typography
          >
        </div>
        <template v-else>
          <div class="align-center d-flex justify-center mb-1 minus-wrapper">
            <c-icon
              color="rgb(var(--v-theme-universal-gray))"
              height="16"
              icon="ph:minus"
              width="16"
            />
          </div>
        </template>
      </template>
      <c-list
        v-for="item in el.items.filter((menuItem) => showItem(menuItem))"
        :key="item.id"
        class="item-list"
        :data-testid="`navi_${item.id}`"
        :opened="userStore.ui_local_data.openedNavGroups"
        return-object
      >
        <c-list-group
          v-if="item.childItems"
          :class="[
            'mb-1',
            {
              'active-group': route.includes(
                getMainRoute(String(item.childItems[0].url))
              ),
            },
          ]"
          :value="item.id"
        >
          <template #activator="{ props, isOpen }">
            <v-list-item
              v-bind="props"
              class="item pr-0"
              density="compact"
              height="40"
              :to="item.url"
              @click="openItem(item, !isOpen)"
            >
              <template #prepend
              ><c-icon class="mr-2" height="20" :icon="item.icon"
              /></template>
              <template #title>
                <c-typography color-class="cta" pointer variant="body-2-500">{{
                  item.label
                }}</c-typography>
              </template>
              <template #append>
                <c-icon
                  class="mr-2"
                  color="rgb(var(--v-theme-universal-gray))"
                  height="20"
                  :icon="isOpen ? 'mdi:chevron-down' : 'mdi:chevron-right'"
                />
              </template>
            </v-list-item>
          </template>

          <v-list-item
            v-for="childItem in item.childItems.filter((el) => el.show)"
            :key="childItem.id"
            active-class="menu__active"
            class="item item-child"
            density="compact"
            height="40"
            :to="childItem.url"
            @click="itemClicked(childItem)"
          >
            <template v-if="childItem.icon && !isRail" #prepend
            ><c-icon class="mr-2" height="20" :icon="childItem.icon"
            /></template>
            <template #title>
              <template v-if="!isRail">
                <c-typography color-class="cta" pointer variant="body-2-500">{{
                  childItem.label
                }}</c-typography>
                <c-icon class="ml-2" height="16" :icon="item.rightIcon" />
              </template>
              <template v-else>
                <div class="align-center d-flex icon-wrapper justify-center">
                  <c-icon
                    color="rgb(var(--v-theme-cta))"
                    height="20"
                    :icon="childItem.icon"
                    width="20"
                  />
                </div>
              </template> </template
            ></v-list-item>
        </c-list-group>
        <v-list-item
          v-else
          active-class="menu__active"
          :class="[
            'item mb-1' ,
            { 'item--no-right-padding': item.rightPaddingDisabled },
            { 'item--rail': isRail },
            { 'item--active': route === item.url },
          ]"
          density="compact"
          height="40"
          :to="item.url"
          @click="itemClicked(item)"
        >
          <template v-if="!isRail" #prepend
          ><c-icon class="mr-2" height="20" :icon="item.icon"
          /></template>
          <template #title>
            <div class="align-center d-flex">
              <template v-if="!isRail">
                <c-typography color-class="cta" pointer variant="body-2-500">{{
                  item.label
                }}</c-typography>
                <c-tag v-if="item.special" class="ml-2">new</c-tag>
                <c-icon class="ml-2" height="16" :icon="item.rightIcon" />
              </template>
              <template v-else>
                <div class="align-center d-flex icon-wrapper justify-center">
                  <c-icon
                    color="rgb(var(--v-theme-cta))"
                    height="20"
                    :icon="item.icon"
                    width="20"
                  />
                </div>
              </template>
            </div>
          </template>
        </v-list-item>
      </c-list>
    </template>
  </div>
</template>

<script lang="ts" setup>
  import { isMobile } from "@/core/utils/mobile";
  import { logout } from "@/core/services/login.service";
  import { Routes } from "@/core/routes/core.guard";
  import { useRouter } from "vue-router";
  import { useStore } from "@/core/store";
  import { useUserStore } from "@/core/store/userStore";
  import { buildMenuItems, type Item } from "./drawerData";
  import { computed, ref, toRefs } from "vue";

  // @ts-ignore
  import { CIcon, CList, CListGroup,  CTag, CTypography } from "@charactr/wooper-ui/atoms";

  import { useAppDrawersStateStore } from "@/core/store/useAppDrawersStateStore";

  //PROPS & EMITS
  const emit = defineEmits(["closeDrawer"]);

  //STORE
  const appDrawersState = useAppDrawersStateStore();
  const { rail, isDefaultRail } = toRefs(appDrawersState);
  const { logout: storeLogout } = useStore();
  const userStore = useUserStore();

  //ROUTER
  const router = useRouter();

  const menuItems = computed(() => buildMenuItems({
    portraitStudio: userStore.ui_local_data.portraitStudioAccess,
    videoTranslation: userStore.ui_local_data.videoTranslationAccess,
  }));

  const route = computed(() => {
    return router.currentRoute.value.path;
  });

  const hover = ref(false);

  const isRail = computed(() => {
    return (
      (false) &&
      rail.value &&
      !isMobile.value &&
      !hover.value
    );
  });

  const openItem = (item: Item, isOpen: boolean) => {
    if (isOpen) {
      userStore.openNavGroup(item.id as string);
    } else {
      userStore.closeNavGroup(item.id as string);
    }
  };

  const itemClicked = (item: Item) => {
    if (item.logout) {
      logoutUser();
    }
    if (item.redirect) {
      window.open(item.redirect, "_blank");
    }
    if (isMobile.value) {
      emit("closeDrawer");
    }

    if (item.action) {
      item.action();
    }
  };

  const logoutUser = async () => {
    await logout();
    console.log("inside logoutUser NavDrawer logout");
    storeLogout();
    router.push({ name: Routes.LOGIN.name });
  };

  const showItem = (item: Item) => {
    if (isMobile.value) {
      return !item.footer && item.show;
    } else {
      return !item.footer && item.show && !item.mobileOnly;
    }
  };

  const getMainRoute = (str: string) => {
    const match = str.match(/\/([^/]+)\//);

    return match ? match[0] : "";
  };
</script>

<style scoped lang="scss">
//@ vuetify icons workaround
:deep(.v-list-item__append i) {
  display: block !important;
}

.menu {
  &__active {
    background: rgb(var(--v-theme-highlight-side-panel)) !important;
    border-radius: 8px;

    svg {
      color: rgb(var(--v-theme-highlight-panel)) !important;
    }

    span {
      color: rgb(var(--v-theme-highlight-panel)) !important;
    }
  }
}

.item {
  padding-left: 10px !important;

  &--no-right-padding {
    padding-right: 0 !important;
  }

  &--rail {
    padding: 0 !important;
  }

  &:hover {
    background: rgb(var(--v-theme-highlight-side-panel)) !important;
    border-radius: 8px;

    span {
      color: rgb(var(--v-theme-cta)) !important;
    }

    svg {
      color: rgb(var(--v-theme-cta)) !important;
    }
  }

  &--active {
    background: rgb(var(--v-theme-highlight-side-panel)) !important;
    border-radius: 8px;

    svg {
      color: rgb(var(--v-theme-highlight-panel)) !important;
    }

    span {
      color: rgb(var(--v-theme-highlight-panel)) !important;
    }
  }

}

.active-group {
  background: rgb(var(--v-theme-highlight-side-panel)) !important;
  border-radius: 8px;
}

.minus-wrapper {
  height: 28px;
  width: 40px;
}

.icon-wrapper {
  height: 40px;
  width: 40px;
}

.footer {
  border-top: 1px solid rgb(var(--v-theme-id-chips)) !important;
  padding: 10px 8px;
}

:deep(.v-list-item > .v-list-item__overlay) {
  opacity: 0% !important;
}

:deep(.v-ripple__container) {
  opacity: 0% !important;
}

:deep(.v-list) {
  padding: 0;
}

.item-child {
  padding-inline-start: 44px !important;
}

.category {
  margin-left: 10px;
  margin-top: 10px;
  white-space: nowrap;
}
</style>
