import { defineStore } from "pinia";

import { RecordingTypes } from "@/core/types/recording.types";
import { ref } from "vue";
import { TOKEN } from "../types/login.types";
import { tokenClientId } from "@/core/api/core.api";
import { useDrawerStore } from "../components/RightDrawer/store";
import { useUserStore } from "../store/userStore";
import { VideoStatus } from "@/core/types/video.types";
import {
  getClientVideo,
  getPortrait,
} from "@/core/services/synthetics.service";
import {
  PLAYGROUND_FEATURES,
  type PlaygroundFeature,
} from "@/core/data/playgroundFeatures";

enum WsMessageType {
  INFO = "info",
  NOTIFICATION = "notification",
}

type WsMessagePayload = {
  status: string;
  type:
    | "video"
    | "portrait"
    | "model"
    | "photo-twin-model"
    | "photo-twin-photo"
    | "video-translation";
  object_id: number | string;
  video_type?: RecordingTypes;
};
type WsMessage = {
  message_type: WsMessageType;
  message: string;
  payload?: WsMessagePayload;
};
export const useWebsocket = defineStore("websocket", () => {
  const message = ref<null | WsMessage>(null);
  const clientId = tokenClientId();
  const drawerStore = useDrawerStore();
  const userStore = useUserStore();

  if (clientId) {
    const wsUrl = import.meta.env.VITE_APP_WS;

    const ws = new WebSocket(`${wsUrl}/v1/clients/${clientId}/ws`);

    const getVariantUrls = async (id: number) => {
      try {
        const response = await getPortrait(id);

        if (response.variantUrls) {
          userStore.updateSyntheticDraftVariantUrls(id, response.variantUrls);
        }
      } catch (error) {
        console.error("Error fetching portrait:", error);
      }
    };

    ws.onopen = () => {
      ws.send(
        JSON.stringify({
          Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
        })
      );
    };

    ws.onmessage = async (wsMessage: MessageEvent) => {
      const parsedMessage = JSON.parse(wsMessage.data);

      message.value = parsedMessage;

      if (parsedMessage.payload) {
        const status = parsedMessage.payload.status || "";
        const id = parsedMessage.payload?.["object_id"] || "";

        if (parsedMessage.payload.type === "video-translation") {

          switch (status) {
            case "running":
            case "pending":
            case "failed":
            case "success":
              drawerStore.updateTranslationStatus(id as string, status);

              if (status === "success") {
                await drawerStore.refreshTranslation(id as string);
              }
              return;
          }
          return;
        } else if (parsedMessage.payload.type === "portrait") {
          userStore.updateSyntheticDraftStatus(id, status);
          getVariantUrls(id);
          return;
        } else if (parsedMessage.payload.type === "model") {
          if (status !== "ready") {
            userStore.updateLipsyncModelStatus(id, status);
          } else {
            userStore.updateZeroShotPicture(id);
          }
          return;
        } else if (parsedMessage.payload.type === "photo-twin-model") {
          if (status === "thumbnail-generated" || status === "ready") {
            userStore.updatePhotoModel(id);
          }
          return;
        } else if (parsedMessage.payload.type === "photo-twin-photo") {
          if (status === "ready") {
            drawerStore.updatePhotoTwinPhotoUrl(id);
          }
          return;
        }

        const videoType = parsedMessage.payload?.["video_type"] || "";
        let mode = PLAYGROUND_FEATURES.VTV.name as PlaygroundFeature["name"];

        if (
          videoType === RecordingTypes.TTV ||
          videoType === RecordingTypes.TTV_RAW ||
          videoType === RecordingTypes.ZERO_SHOT ||
          videoType === RecordingTypes.DUBBING
        ) {
          mode = PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name;
        }
        if (!id || !videoType || !status) {
          return;
        }

        drawerStore.updateVideoStatus(id, status, mode);

        if (status === VideoStatus.READY) {
          const video = await getClientVideo(id);

          drawerStore.updateVideoRecording(video, mode);
        }
      }
    };
  }

  const sendMessage = () => {
    //todo implement if required
  };

  return {
    message,
    sendMessage,
  };
});
