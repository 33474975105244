import { AlertModes, UNKNOWN_PORTRAIT_ID } from "@/core/types/other.types";
import { defineStore } from "pinia";
import { getRecordings } from "@/core/services/playground.service";
import { useUserStore } from "@/core/store/userStore";
import {
  getAllSyntheticVideos,
  getSyntheticVideos,
} from "@/core/services/synthetics.service";
import { getLipsyncVideos } from "@/core/services/lipsync.service";
import {
  getPhotoTwinPhotoData,
  getPhotoTwinPhotos,
} from "@/core/services/photoTwins.service";
import {
  PLAYGROUND_FEATURES,
  type PlaygroundFeature,
} from "@/core/data/playgroundFeatures";

import { RecordingTypes } from "@/core/types/recording.types";

import type {
  PhotoTwinPhoto,
  SyntheticModel,
  SyntheticPortrait,
} from "@/core/types/synthetic.types";
import { type SyntheticVideo, VideoStatus } from "@/core/types/video.types";
import { useSnackbarStore } from "@/core/store/useSnackbarStore";
import {
  getVideoTranslations,
  getVideoTranslation,
} from "@/core/services/videoTranslation.service";
import type { VideoTranslation } from "@/core/types/videoTranslation.types";

const MOCKED_VIDEO_ID = 12345;

export const useDrawerStore = defineStore({
  id: "rightDrawer",
  state: () => ({
    recordings: {
      [PLAYGROUND_FEATURES.STS.name]: [] as Array<any>,
      [PLAYGROUND_FEATURES.TTS.name]: [] as Array<any>,
      [PLAYGROUND_FEATURES.VTV.name]: [] as Array<SyntheticVideo>,
      [PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name]: [] as Array<SyntheticVideo>,
      twin: [] as Array<SyntheticVideo>,
      translations: [] as Array<VideoTranslation>,
    },
    draftRecording: null as null | SyntheticVideo,
    loading: false,
    activePlayingVideoId: -1,
    checkingVideo: true,
    showInfo: false,
    newVideoFromVideoCreator: false,
    currentModelPhotos: [] as Array<any>,
    promptGalleryItems: [] as Array<any>,
    loadingModelPhotos: true,
  }),
  actions: {
    cleanUp() {
      this.recordings = {
        [PLAYGROUND_FEATURES.STS.name]: [],
        [PLAYGROUND_FEATURES.TTS.name]: [],
        [PLAYGROUND_FEATURES.VTV.name]: [],
        [PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name]: [],
        [PLAYGROUND_FEATURES.VIDEO_CREATOR.name]: [],
        twin: [],
        translations: [],
      };
      this.activePlayingVideoId = -1;
    },
    async fetchItems(
      fetchFunction: (mode: string) => Promise<Array<any>>,
      mode: string
    ) {
      const { showSnackbar } = useSnackbarStore();

      try {
        const recordings = await fetchFunction(mode);

        this.recordings[mode] = recordings;
      } catch (e: any) {
        showSnackbar(
          e.response?.data.message || "Error occured",
          AlertModes.ERROR
        );
      } finally {
        this.loading = false;
      }
    },
    async loadRecordings(mode: string) {
      await this.fetchItems(getRecordings, mode);
    },

    async loadUserVideos() {
      const { showSnackbar } = useSnackbarStore();

      try {
        await this.loadAllSyntheticVideos();
      } catch (error: any) {
        showSnackbar(error.response?.data.message, AlertModes.ERROR);
        return;
      } finally {
        this.loading = false;
      }
    },

    async loadVideos(delay = -1) {
      if (
        !this.recordings.vtv.length ||
        !this.recordings.videoSynthesis.length
      ) {
        this.loading = true;

        if (delay > -1) {
          setTimeout(async () => {
            await this.loadUserVideos();
          }, delay);
        } else {
          await this.loadUserVideos();
        }
      }
    },

    async loadTwinVideos(id: string | number, lipsync: boolean) {
      this.recordings.twin = [];

      const { showSnackbar } = useSnackbarStore();

      try {
        this.loading = true;
        if (lipsync) {
          this.recordings.twin = await getLipsyncVideos(id as string);
        } else {
          this.recordings.twin = await getSyntheticVideos(id as string);
        }
      } catch (e: any) {
        showSnackbar(
          e.response?.data.message || "Error while loading twin videos",
          AlertModes.ERROR
        );
      } finally {
        this.loading = false;
      }
    },
    async loadPhotoModelsPhotos(id: string | number) {
      this.currentModelPhotos = [];
      this.loadingModelPhotos = true;

      const { showSnackbar } = useSnackbarStore();

      try {
        this.currentModelPhotos = await getPhotoTwinPhotos(id as number);
      } catch (e: any) {
        showSnackbar(
          e.response?.data.message || "Error while loading photos",
          AlertModes.ERROR
        );
      } finally {
        this.loadingModelPhotos = false;
      }
    },
    async updatePhotoTwinPhotoUrl(id: number) {
      const el = await getPhotoTwinPhotoData(id);

      const existingPictureIndex = this.currentModelPhotos.findIndex(
        (el) => el.id === id
      );

      if (existingPictureIndex > -1) {
        this.currentModelPhotos[existingPictureIndex] = el;
      }
    },
    updatePhotoTwinPhoto(item: PhotoTwinPhoto) {
      const existingPictureIndex = this.currentModelPhotos.findIndex(
        (el) => el.id === item.id
      );

      if (existingPictureIndex > -1) {
        this.currentModelPhotos[existingPictureIndex] = item;
      }
    },

    async loadAllSyntheticVideos() {
      this.loading = true;
      const allVideoRecordings = await getAllSyntheticVideos();

      this.loading = false;

      this.recordings.vtv = allVideoRecordings.filter(
        (rec) => rec.type === RecordingTypes.VTV
      );
      this.recordings.videoSynthesis = allVideoRecordings.filter(
        (rec) =>
          rec.type === RecordingTypes.TTV ||
          rec.type === RecordingTypes.TTV_RAW ||
          rec.type === RecordingTypes.ZERO_SHOT ||
          rec.type === RecordingTypes.DUBBING
      );
    },

    prependVideo(
      video: SyntheticVideo,
      mode: PlaygroundFeature["name"] | "twin"
    ) {
      this.deleteMockedVideo();
      this.recordings[mode as "vtv" | "videoSynthesis" | "twin"].unshift(video);
      this.showInfoMessage();
    },

    //we have to add this function after clicking create button in Video Creator
    prependMockedVideo(portraitId: number, lipsync = true) {
      this.setNewVideoFromVideoCreator();
      const userStore = useUserStore();

      let portrait = {} as SyntheticModel | SyntheticPortrait;

      if (lipsync) {
        const findPortrait = userStore.listOfGalleryPictures.find(
          (el) => el.id === portraitId
        );
        if (findPortrait) portrait = findPortrait;
      } else {
        const findModel = userStore.listOfSyntheticModels.find(
          (el) => el.id === portraitId
        );
        if (findModel) portrait = findModel;
      }

      const imgUrl =
        portraitId === UNKNOWN_PORTRAIT_ID
          ? "/images/unknown2.png"
          : lipsync
          ? (portrait as SyntheticPortrait).url
          : (portrait as SyntheticModel).coverUrl;

      this.draftRecording = {
        status: VideoStatus.UPLOADING,
        imageLoadingUrl: imgUrl,
        url: "",
        id: MOCKED_VIDEO_ID,
        createdAt: new Date().toISOString(),
        clientId: 0,
        portraitId,
        name: portrait.name,
        videoDurationMs: 22000,
        sharingActive: true,
        shareId: "",
      };
    },
    showInfoMessage() {
      this.showInfo = true;
      setTimeout(() => {
        this.showInfo = false;
      }, 8000);
    },

    // we have to execute this function when response from create video is 200 (ok) and we have real video as response
    deleteMockedVideo() {
      this.draftRecording = null;
    },
    async prependAudio(mode: string) {
      const { showSnackbar } = useSnackbarStore();

      try {
        const recordings = await getRecordings(mode);

        if (recordings[0].id) {
          this.recordings[mode as "vc" | "tts"].unshift(recordings[0]);
        }
      } catch (error: any) {
        showSnackbar(error.response?.data.message, AlertModes.ERROR);
        return;
      }
    },

    deleteRecording(mode: PlaygroundFeature["name"], id: number) {
      this.recordings[mode as "vc" | "tts"] = this.recordings[
        mode as "vc" | "tts"
      ].filter((el) => el.id !== id);
    },
    updateRecordingName(mode: string, id: number, name: string, url: string) {
      const index = this.recordings[mode].findIndex(
        (recording) => recording.id === id
      );

      if (index !== -1) {
        this.recordings[mode][index].recordingName = name;
        this.recordings[mode][index].recordingUrl = url;
      }
    },
    deleteVideo(id: number, mode: PlaygroundFeature = PLAYGROUND_FEATURES.VTV) {
      this.recordings[mode.name] = this.recordings[mode.name].filter(
        (el) => el.id !== id
      );
      this.recordings.twin = this.recordings.twin.filter((el) => el.id !== id);
    },
    updateVideoName(
      id: number,
      name: string,
      mode: PlaygroundFeature = PLAYGROUND_FEATURES.VTV
    ) {
      const index = this.recordings[mode.name].findIndex(
        (recording) => recording.id === id
      );

      if (index !== -1) {
        this.recordings[mode.name][index].name = name;
      }
    },

    updateVideoRecording(
      video: SyntheticVideo,
      mode: PlaygroundFeature["name"]
    ) {
      const index = this.recordings[mode].findIndex(
        (item) => item.id === video.id
      );

      const twinIndex = this.recordings.twin.findIndex(
        (item) => item.id === video.id
      );

      if (index > -1) {
        this.recordings[mode][index] = video;
      }

      if (twinIndex > -1) {
        this.recordings.twin[twinIndex] = video;
      }
    },

    updateVideoStatus(
      id: number,
      status: VideoStatus,
      mode: PlaygroundFeature["name"]
    ) {
      const index = this.recordings[mode].findIndex((item) => item.id === id);

      const twinIndex = this.recordings.twin.findIndex(
        (item) => item.id === id
      );

      if (index > -1) {
        this.recordings[mode][index].status = status;
      }

      if (twinIndex > -1) {
        this.recordings.twin[twinIndex].status = status;
      }
    },

    setLoading(value: boolean) {
      this.loading = value;
    },
    cancelCheckingVideo() {
      this.checkingVideo = false;
    },
    setNewVideoFromVideoCreator() {
      this.newVideoFromVideoCreator = true;
    },
    prependModelPhoto(photo: PhotoTwinPhoto) {
      this.modelPhotos.unshift(photo);
    },
    async loadTranslations() {
      const { showSnackbar } = useSnackbarStore();

      try {
        this.loading = true;
        const translations = await getVideoTranslations();

        this.recordings.translations = translations;
      } catch (e: any) {
        showSnackbar(
          e.response?.data.message || "Error while loading translations",
          AlertModes.ERROR
        );
      } finally {
        this.loading = false;
      }
    },
    prependTranslation(translation: VideoTranslation) {
      this.recordings.translations = [
        translation,
        ...this.recordings.translations,
      ];
      this.showInfoMessage();
    },
    deleteTranslation(id: string) {
      this.recordings.translations = this.recordings.translations.filter(
        (el) => el.id !== id
      );
    },
    updateTranslationName(id: string, name: string) {
      const index = this.recordings.translations.findIndex(
        (translation) => translation.id === id
      );

      if (index !== -1) {
        this.recordings.translations[index].name = name;
      }
    },
    updateTranslationStatus(id: string, status: string) {
      const index = this.recordings.translations.findIndex(
        (translation) => translation.id === id
      );

      if (index !== -1) {
        if (status === "success") {
          this.recordings.translations[index].status = VideoStatus.READY;
        } else {
          this.recordings.translations[index].status = status;
        }
      }
    },
    async refreshTranslation(id: string) {

      const { showSnackbar } = useSnackbarStore();

      try {
        const translation = await getVideoTranslation(id);

        const index = this.recordings.translations.findIndex(
          (t) => t.id === `video_translation_${id}`
        );

        if (index !== -1) {
          this.recordings.translations[index] = translation;
        }
      } catch (e: any) {
        showSnackbar(
          e.response?.data.message || "Error while refreshing translation",
          AlertModes.ERROR
        );
      }
    },
  },
  getters: {
    ttsRecordings(state) {
      state.recordings[PLAYGROUND_FEATURES.TTS.name].forEach(
        (r) => (r.mode = PLAYGROUND_FEATURES.TTS.name)
      );
      return state.recordings[PLAYGROUND_FEATURES.TTS.name];
    },
    vcRecordings(state) {
      state.recordings[PLAYGROUND_FEATURES.STS.name].forEach(
        (r) => (r.mode = PLAYGROUND_FEATURES.STS.name)
      );
      return state.recordings[PLAYGROUND_FEATURES.STS.name];
    },
    vtvRecordings(state) {
      return state.recordings[PLAYGROUND_FEATURES.VTV.name];
    },
    twinRecordings(state) {
      return state.recordings.twin;
    },
    modelPhotos(state) {
      return state.currentModelPhotos;
    },
    videoSyntheticsRecordings(state) {
      return state.recordings[PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name];
    },
    videoCreatorRecordings(state) {
      return ([] as SyntheticVideo[])
        .concat(
          state.draftRecording ? [state.draftRecording] : [],
          state.recordings[PLAYGROUND_FEATURES.VTV.name] || [],
          state.recordings[PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name] || []
        )
        .sort(
          (a: SyntheticVideo, b: SyntheticVideo) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
    },
    readyVideoCreatorRecordings() {
      if (Array.isArray(this.videoCreatorRecordings)) {
        return this.videoCreatorRecordings.filter(
          (r: SyntheticVideo) => r.status === VideoStatus.READY
        );
      }
      return [];
    },
    translationRecordings(state) {
      return state.recordings.translations;
    },
  },
});
