<template>
  <c-dialog
    :confirm-option="false"
    min-width="226"
    :model-value="modelValue"
    variant="secondary"
    :width="isMobile ? '226' : 726"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #title>Audio Creator Tools</template>
    <template #content>
      <div :class="['d-flex tools-content mt-6 ma-1', { 'flex-column justify-center w-100 align-center': isMobile }]">
        <feature-card
          v-for="feature in audioTools"
          :key="feature.name"
          :desc="feature.description"
          :img="
            userStore.activeTheme === Themes.LIGHT
              ? feature.imgLight
              : feature.imgDark
          "
          :img-alt="feature.imgAlt"
          :name="feature.name"
          @click="navigateTo(feature)"
        />
      </div>
    </template>
  </c-dialog>

</template>

  <script lang="ts" setup>
  import type { AppFeatureCard } from "@/core/data/appFeatures";
  import FeatureCard from "@/core/components/FeatureCard.vue";

  import { useUserStore } from "@/core/store/userStore";

  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";

  import { Routes } from "@/core/routes/core.guard";
  import { useRouter } from "vue-router";

  import { isMobile } from "@/core/utils/mobile";
  import { PLAYGROUND_FEATURES } from "@/core/data/playgroundFeatures";
  import { Themes } from "@/core/types/other.types";

  const props = defineProps({
    modelValue: {
      type: Boolean,
      required: true,
    },
  });

  const emit = defineEmits(["update:modelValue"]);

  const audioTools: Array<AppFeatureCard> = [
    // {
    //   name: "Speech to Speech",
    //   description: "Change any voice input into a dynamic AI voice",
    //   imgDark: "/images/home/dark/audioCreatorSpeech.png",
    //   imgLight: "/images/home/light/audioCreatorSpeech.png",
    //   imgAlt: "Speech to Speech",
    //   testId: "sts",
    //   routeName: Routes.SPEECH_TO_SPEECH.name,
    //   routePath: Routes.SPEECH_TO_SPEECH.path,
    //   mode: PLAYGROUND_FEATURES.STS,
    // },
    {
      name: "Text to Speech",
      description: "Transform text into natural AI-generated speech",
      imgDark: "/images/home/dark/audioCreatorText.png",
      imgLight: "/images/home/light/audioCreatorText.png",
      imgAlt: "Text to Speech",
      testId: "tts",
      routeName: Routes.TEXT_TO_SPEECH.name,
      routePath: Routes.TEXT_TO_SPEECH.path,
      mode: PLAYGROUND_FEATURES.TTS,
    },
    {
      name: "Voice Cloning",
      description: "Clone your voice with a 10-second audio sample",
      imgDark: "/images/home/dark/audioCreatorCloning.png",
      imgLight: "/images/home/light/audioCreatorCloning.png",
      imgAlt: "audio creator image",
      testId: "cloning",
      routeName: Routes.VOICE_CLONING.name,
      routePath: Routes.VOICE_CLONING.path,
      mode: PLAYGROUND_FEATURES.VOICE_CLONING,
    },
  ];

  const userStore = useUserStore();
  const router = useRouter();

  const navigateTo = (feature: AppFeatureCard) => {
    if (feature.mode?.name) {
      router.push({ name: feature.routeName });
    }
  };
  </script>

  <style lang="scss" scoped>
  .tools-content {
    gap: 12px;
  }
  </style>
