<template>
  <c-dialog
    :cancel-option="false"
    :confirm-disabled="!data.video || data.loading"
    :confirm-loading="data.loading"
    :model-value="modelValue"
    :price="price"
    variant="primary"
    width="600"
    @cancel="emit('update:modelValue', false)"
    @confirm="confirm"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #title>Video Translation</template>

    <template #content>
      <div class="d-flex flex-column mb-4">
        <c-typography
          class="d-block mb-2"
          :color-class="data.loading ? 'copy-secondary-lock' : 'copy-secondary'"
          variant="body-2-600"
        >
          Translate to:
        </c-typography>
        <div :class="[{ 'opacity-50': data.loading }]">
          <c-language-select
            v-model="data.language"
            :disabled="data.loading"
            :menu-items="languageOptions"
          />
        </div>
      </div>

      <circle-loader v-if="data.loading" height="400" title="Uploading video..." />

      <template v-else>
        <drag-and-drop
          v-if="!data.video"
          class="upload-zone"
          :supported-formats="VIDEO_FORMATS"
          test-id="video-translation-upload"
          @choose-file="chooseFile"
          @drop-file="handleFileUpload($event, true)"
        />

        <Video
          v-else
          id="player"
          :file="data.video"
          :loading="false"
          max-height="calc(100vh - 450px)"
          :mobile="isMobile"
          :show-details="true"
          @delete="cleanUp"
        />
      </template>

      <div class="align-center d-flex justify-space-between mt-4">
        <div class="align-center d-flex justify-start">
          <c-switch
            v-model="data.AIEnabled"
            :disabled="data.loading"
            :gap="8"
            :height="22"
            typography="body-2-400"
          />
          <div class="align-center d-flex ml-2">
            <c-typography
              :color-class="
                data.loading ? 'copy-secondary-lock' : 'copy-secondary'
              "
              pointer
              variant="body-2-400"
              @click="data.loading ? null : (data.AIEnabled = !data.AIEnabled)"
            >
              Sync lip movements with translated audio
            </c-typography>
          </div>
        </div>
      </div>
    </template>
    <template #confirm-label>Translate</template>
  </c-dialog>

  <error-dialog
    v-model="data.loadingError"
    :close-option="false"
    confirm-variant="secondary-web"
    :errors="data.loadingErrorMessage.description"
    :title="data.loadingErrorMessage.title"
  />
</template>

<script setup lang="ts">
  import { computed, reactive } from "vue";
  import type { LoadedVideoFile } from "@/core/types/recording.types";
  import { useUserStore } from "@/core/store/userStore";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";
  import { AlertModes } from "@/core/types/other.types";
  import useFileLoader from "@/core/composables/useMediaFileLoader";
  import { createVideoTranslation } from "@/core/services/videoTranslation.service";
  import { isMobile } from "@/core/utils/mobile";
  import { storeToRefs } from "pinia";
  import DragAndDrop from "@/core/components/DragAndDrop.vue";
  import Video from "@/modules/playground/components/videoToVideo/Video.vue";
  import ErrorDialog from "@/core/components/Dialogs/ErrorDialog.vue";
  import CircleLoader from "@/core/components/Container/CircleLoader.vue";

  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  import { CLanguageSelect, CSwitch, CTypography } from "@charactr/wooper-ui/atoms";

  const props = defineProps({
    modelValue: {
      type: Boolean,
      required: true,
    },
  });

  const emit = defineEmits(["update:modelValue", "success"]);

  const VIDEO_FORMATS = ["mp4", "mov", "webm"];
  const userStore = useUserStore();
  const snackbarStore = useSnackbarStore();
  const { loadVideoFile } = useFileLoader();

  const { billableUnitDurationS, videoHQCostPerUnit } =
    storeToRefs(userStore);

  const data = reactive({
    loading: false,
    AIEnabled: true,
    language: "English",
    video: null as LoadedVideoFile | null,
    loadingError: false,
    loadingErrorMessage: { title: "", description: "" },
  });

  const languageOptions = [
    { label: "English", value: "English", img: "/images/flags/english.webp" },
    { label: "Spanish", value: "Spanish", img: "/images/flags/spanish.webp" },
    { label: "French", value: "French", img: "/images/flags/french.webp" },
    { label: "Hindi", value: "Hindi", img: "/images/flags/hindi.webp" },
    { label: "German", value: "German", img: "/images/flags/german.webp" },
    { label: "Polish", value: "Polish", img: "/images/flags/polish.webp" },
    {
      label: "Portuguese",
      value: "Portuguese",
      img: "/images/flags/portuguese.webp",
    },
    { label: "Mandarin", value: "Mandarin", img: "/images/flags/mandarin.webp" },
    { label: "Arabic", value: "Arabic", img: "/images/flags/arabic.webp" },
    {
      label: "Ukrainian",
      value: "Ukrainian",
      img: "/images/flags/ukrainian.webp",
    },
  ];

  const price = computed(() => {
    //TODO: change videoExpressCost to videoTranslationCost
    return data.video
      ? Math.ceil(data.video.duration / Number(billableUnitDurationS.value)) *
        Number(videoHQCostPerUnit.value)
      : "";
  });

  const cleanUp = () => {
    data.video = null;
    data.AIEnabled = true;
    data.loading = false;
    data.loadingError = false;
    data.loadingErrorMessage = { title: "", description: "" };
  };

  const chooseFile = () => {
    const input = document.createElement("input");

    input.type = "file";
    input.accept = VIDEO_FORMATS.map((format) => `.${format}`).join(",");
    input.onchange = (event: any) => handleFileUpload(event);
    input.click();
  };

  const handleFileUpload = async (event: any, isDropped = false) => {
    try {
      const files = isDropped ? event.dataTransfer.files : event.target.files;

      const videoFile = await loadVideoFile(files, {
        minDuration: 0,
        maxDuration: 60,
        maxMegabytes: 1000,
        supportedExtensions: VIDEO_FORMATS,
      });

      cleanUp();
      data.video = videoFile;
    } catch (error: any) {
      console.error(error);
      snackbarStore.showSnackbar(
        error.description || "Error uploading video",
        AlertModes.ERROR
      );
    }
  };

  const confirm = async () => {
    if (!data.video) return;

    try {
      data.loading = true;

      const result = await createVideoTranslation({
        video: data.video.file,
        videoDurationMs: data.video.duration * 1000,
        outputLanguage: data.language,
        lipsync: data.AIEnabled,
      });

      if (result) {
        snackbarStore.showSnackbar(
          "We're translating your video. It will be ready soon!",
          AlertModes.SUCCESS
        );
        emit("success", result);
        emit("update:modelValue", false);
        cleanUp();
      }
    } catch (e: any) {
      console.error(e);
      data.loadingErrorMessage = {
        title: "Translation Error",
        description:
          e.response?.data.message || "Error occurred during translation",
      };
      data.loadingError = true;
    } finally {
      data.loading = false;
    }
  };
</script>

<style lang="scss" scoped>
.upload-zone {
  min-height: 400px !important;
}

.gap-4 {
  gap: 16px;
}

.opacity-50 {
  opacity: 0.5;
}
</style>
