import { BTN_TOGGLE_MODES } from "@/modules/playground/data/playground.data";
import { RECORDING_TABS } from "../types/recording.types";
import { Themes } from "../types/other.types";
import { VideoPlayerVariants } from "../types/video.types";

export const defaults = {
  theme: Themes.LIGHT,
  portraitStudioAccess: false,
  multiTextAccess: false,
  videoTranslationAccess: false,
  openedNavGroups: [],
  selectedMenuFilters: {},
  gallerySorting: "newestFirst",
  translatedGallerySorting: "newestFirst",
  galleryRecentMainTab: RECORDING_TABS.AUDIO,
  galleryVideoVariant: VideoPlayerVariants.GALLERY,
  galleryVideoOrigin: null,
  galleryAudioOrigin: null,
  googleAvatar: "",
  videoCreatorFirstTimeDesktopVisit: true,
  videoCreatorFirstTimeMobileVisit: true,
  shareVideoFirstVisit: true,
  recentVideoMode: BTN_TOGGLE_MODES.TEXT,
};
