import account from "@/modules/account/routes";
import videoTwinStudio from "@/modules/videoTwinStudio/routes";
import apiKeys from "@/modules/apiKeys/routes";
import billing from "@/modules/billing/routes";
import contact from "@/modules/contact/routes";
import core from "@/core/routes";
import login from "@/modules/login/routes";
import offers from "@/modules/offers/routes";
import playground from "@/modules/playground/routes";
import recordingsHistory from "@/modules/recordingsHistory/routes";
import share from "@/modules/share/routes";
import shop from "@/modules/shop/routes";
import statistics from "@/modules/statistics/routes";
import syntheticCreator from "@/modules/syntheticCreator/routes";
import videoCreator from "@/modules/videoCreator/routes";
import videoTwinCreator from "@/modules/videoTwinCreator/routes";
import voicePlayground from "@/modules/voicesPlayground/routes";
import photoTwinStudio from "@/modules/photoTwinStudio/routes";
import videoTranslation from "@/modules/videoTranslation/routes";

import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
} from "vue-router";

const routes = [
  ...core,
  ...account,
  ...apiKeys,
  ...billing,
  ...contact,
  ...recordingsHistory,
  ...share,
  ...statistics,
  ...syntheticCreator,
  ...login,
  ...videoCreator,
  ...voicePlayground,
  ...offers,
  ...playground,
  ...shop,
  ...videoTwinCreator,
  ...videoTwinStudio,
  ...photoTwinStudio,
  ...videoTranslation,
];

export default createRouter({
  history: createWebHistory(),
  routes: routes as RouteRecordRaw[],
});
