import { Routes } from "../routes/core.guard";

export enum StatisticsOption {
  TTS = "tts",
  VC = "vc",
  VTV = "VTV",
  GEMELO_REGULAR = "gemelo_regular",
  GEMELO_FULLHD = "gemelo_full_hd",
}

const TTS = {
  name: "tts",
  label: "Text to Speech",
  icon: "ph:text-t",
  abbrev: "TTS",
  routeName: Routes.TEXT_TO_SPEECH.name,
  dialog: {
    labelExtra: "",
    title: "What is Text-to-Speech",
    description:
      "Transform your written content into spoken words with our text-to-speech feature. Simply input your desired text and choose from a variety of voices in the dropdown menu to instantly hear it spoken aloud. Enjoy the convenience and versatility of this powerful tool right from our API's intuitive user interface.",
  },
  statistics: [
    {
      label: "Text to Speech",
      value: StatisticsOption.TTS,
    },
  ],
  showStats: true,
  premium: false,
};

const STS = {
  name: "vc",
  label: "Speech to Speech",
  icon: "ph:microphone-fill",
  abbrev: "STS",
  routeName: Routes.SPEECH_TO_SPEECH.name,
  dialog: {
    labelExtra: "",
    title: "What is Speech to Speech",
    description:
      "Experience the intuitive user interface of our API's voice-to-voice feature. With a variety of voices to choose from in the dropdown menu and the ability to record your own voice, the UI empowers you to effortlessly transform your audio content. Unleash your creative potential and take advantage of this powerful and user-friendly tool.",
  },
  statistics: [
    {
      label: "Speech to Speech",
      value: StatisticsOption.VC,
    },
  ],
  showStats: true,
  premium: false,
};

const VOICE_CLONING = {
  name: "voiceCloning",
  label: "Voice Cloning",
  dialog: {
    labelExtra: "",
    title: "What is Voice Cloning",
    description:
      "Voice cloning technology digitally replicates a person’s voice using AI, enabling realistic speech synthesis for purposes like voice-to-voice conversion, text-to-speech, and video audio conversion.",
  },
};

const TWIN_CREATOR = {
  name: "twinCreator",
  label: "AI Twin Creator",
  dialog: {
    labelExtra: "",
    title: "",
    description: "",
  },
};

const VTV = {
  name: "vtv",
  label: "Video to Video",
  dialog: {
    labelExtra: "AI Beta",
    title: "What is Video to Video",
    description:
      "Choose a Gemelo character, pick a voice from the library, upload a video of yourself talking, and Voila! The system transforms it into a new video with the synthetic character, replicating your expressions and using the selected voice.",
  },
  statistics: [
    {
      label: "Video to Video",
      value: StatisticsOption.VTV,
    },
  ],
  showStats: true,
};

const VIDEO_SYNTHESIS = {
  name: "videoSynthesis",
  label: "Gemelo to Video",
  dialog: {
    labelExtra: "AI Beta",
    title: "What is Text to Video",
    description:
      "Select a Gemelo character, pick a voice from the library, type your message, and you're ready! The system transforms it all into a new video with the synthetic character, generating your text into speech with the selected voice.",
  },
  statistics: [
    {
      label: "Gemelo to Video (regular)",
      value: StatisticsOption.GEMELO_REGULAR,
    },
    {
      label: "Gemelo to Video (full HD)",
      value: StatisticsOption.GEMELO_FULLHD,
    },
  ],
  showStats: true,
};

const SYNTH_UPLOAD_PIC = {
  name: "synthUploadPic",
  label: "Add Synthetic Gemelo",
  dialog: {
    labelExtra: "",
    title: "",
    description: "",
  },
};

const VIDEO_CREATOR = {
  name: "videoCreator",
  label: "Video Creator",
  icon: "ph:video-camera-fill",
  routeName: Routes.AI_TWIN_STUDIO.name,
  dialog: {
    labelExtra: "",
    title: "What is Video Twin Studio",
    confirmLabel: "Start Guide Tour",
    description:
      "Gemelo AI’ Video Creator empowers users to easily produce videos featuring an avatar named Gemelo. Users can create videos by converting written content into spoken dialogue, uploading video or audio files, or selecting audio files from their previous recordings. They can also customize Gemelo's appearance and choose their preferred voice for the avatar. With Gemelo AI's robust video creation tools, users enjoy unmatched convenience and flexibility in crafting captivating visual content.",
  },
};

const VIDEO_TRANSLATION = {
  name: "videoTranslation",
  label: "Video Translation",
  dialog: {
    labelExtra: "",
    title: "",
    description: "",
  },
};

type StatsFlowType = {
  label: string;
  value: string;
};
export interface PlaygroundFeature {
  name: string;
  label: string;
  icon?: string;
  abbrev?: string;
  routeName?: string;
  dialog: {
    title: string;
    description: string;
    labelExtra: string;
    confirmLabel?: string;
  };
  statistics?: Array<StatsFlowType>;
  showStats?: boolean;
  premium?: boolean;
}
export type PlaygroundFeatures = Record<string, PlaygroundFeature>;

export const PLAYGROUND_FEATURES: PlaygroundFeatures = {
  TTS,
  STS,
  VOICE_CLONING,
  TWIN_CREATOR,
  VTV,
  VIDEO_SYNTHESIS,
  SYNTH_UPLOAD_PIC,
  VIDEO_CREATOR,
  VIDEO_TRANSLATION,
};
